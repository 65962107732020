import { ContainerModule, interfaces } from "inversify";
import { newableTwilsockClientRTTI, twilsockRTTI, productIdRTTI, twilsockClientFactoryRTTI } from "./websocket.rtti";
import { NewableTwilsockClient, TwilsockOptions, TwilsockClient } from "./TwilsockClient/TwilsockClient";
import { Twilsock } from "./Twilsock/Twilsock";
import { TwilsockImpl } from "./Twilsock/TwilsockImpl";
import { TwilsockClientFactory } from "~/modules/websocket/TwilsockClientFactory/TwilsockClientFactory";
import { getTwilsockClient } from "~/modules/websocket/TwilsockClientFactory/getTwilsockClient";

export const websocketModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<NewableTwilsockClient>(newableTwilsockClientRTTI).toConstructor(TwilsockClient);
    bind<string>(productIdRTTI).toConstantValue("flex");
    bind<Twilsock>(twilsockRTTI).to(TwilsockImpl).inSingletonScope();
    bind<TwilsockClientFactory>(twilsockClientFactoryRTTI).toFactory<TwilsockClient>((context: interfaces.Context) => {
        return (token: string, productId: string, options?: TwilsockOptions) => {
            return getTwilsockClient(context.container, token, productId, options);
        };
    });
});
