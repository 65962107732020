import { ContainerModule, interfaces } from "inversify";
import {
    AccountConfig,
    AccountConfigProvider,
    AccountConfigData,
    EnvironmentConfig,
    PublicConfigProvider,
    AccountSidOption,
    RuntimeDomainOption,
    PublicConfig,
    accountConfigRTTI,
    accountConfigProviderRTTI,
    environmentConfigRTTI,
    publicConfigProviderRTTI,
    configDataContainerRTTI
} from "~/modules/config";
import { accountConfigProvider } from "./AccountConfigProvider/AccountConfigProvider";
import { accountConfigUpdaterRTTI } from "./config.rtti";
import { getEnvironmentConfig } from "./EnvironmentConfig/EnvironmentConfigImpl";
import { AccountConfigStore } from "./AccountConfig/AccountConfigImpl/AccountConfigStore/AccountConfigStore";
import { getPublicConfig } from "./PublicConfigProvider/getPublicConfig";
import { XOR } from "~/utils/utilityTypes";
import { updateAccountConfig } from "./AccountConfig/AccountConfigImpl/AccountConfigUpdate/updateAccountConfig";
import { ConfigDataContainer } from "./AccountConfig/ConfigDataContainer";
import { AccountConfigDataContainer } from "./AccountConfig/AccountConfigImpl/AccountConfigDataContainer/AccountConfigDataContainer";
import { AccountConfigUpdater } from "./AccountConfig/AccountConfigUpdater";

export const configModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<AccountConfig>(accountConfigRTTI).to(AccountConfigStore).inSingletonScope();
    bind<ConfigDataContainer<AccountConfigData>>(configDataContainerRTTI)
        .to(AccountConfigDataContainer)
        .inSingletonScope();
    bind<AccountConfigProvider>(accountConfigProviderRTTI).toProvider<AccountConfig>((context: interfaces.Context) => {
        return () => accountConfigProvider(context.container);
    });
    bind<AccountConfigUpdater>(accountConfigUpdaterRTTI).toDynamicValue((context: interfaces.Context) => {
        return (accountSid: string, config: Partial<AccountConfigData>) =>
            updateAccountConfig(context.container, accountSid, config);
    });
    bind<EnvironmentConfig>(environmentConfigRTTI).toDynamicValue(getEnvironmentConfig);
    bind<PublicConfigProvider>(publicConfigProviderRTTI).toProvider<PublicConfig>((context: interfaces.Context) => {
        return (option: XOR<AccountSidOption, RuntimeDomainOption>) => getPublicConfig(context.container, option);
    });
});
