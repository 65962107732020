import { QueueStats, WorkerStats, TaskChannelStats, TasksByTime } from "~/modules/stats";
import { Subscribable, SubscribableOptions, SyncMapItem } from "~/modules/sync";
import { QueueStatsService } from "~/backend/generated/RealtimeQueueStats/api/queueStats.serviceInterface";
import { Metadata } from "~/backend/generated/RealtimeQueueStats/model/metadata";
import {
    InternalErrorCode,
    ThrowErrorFromErrorResponseFunction,
    ReThrowErrorWithCustomMessageFunction,
    ErrorSeverity
} from "~/modules/error";
import { QueueStatsCallback, QueueStatsItemKey } from "~/modules/stats/RealtimeStats/QueueStats/QueueStats";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { Enumerable } from "~/utils/decorators/Enumerable";

export class QueueStatsImpl implements Subscribable<QueueStats, QueueStatsCallback> {
    readonly sid: string;

    readonly tasks: TasksByTime;

    readonly #metadata: Subscribable<Metadata>;

    readonly #queueStatsService: QueueStatsService;

    readonly #subscribers: Function[] = [];

    readonly #throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction;

    readonly #rethrowErrorwithCustomFunction: ReThrowErrorWithCustomMessageFunction;

    constructor(
        queueSid: string,
        queueStatsService: QueueStatsService,
        tasksByTime: TasksByTime,
        metadataMapItem: SyncMapItem<Metadata>,
        throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction,
        rethrowErrorWithCustomFunction: ReThrowErrorWithCustomMessageFunction
    ) {
        this.sid = queueSid;
        this.#queueStatsService = queueStatsService;
        this.tasks = tasksByTime;
        this.#metadata = metadataMapItem.getSubscribable();

        this.#throwErrorFromErrorResponse = throwErrorFromErrorResponse;
        this.#rethrowErrorwithCustomFunction = rethrowErrorWithCustomFunction;
    }

    @Enumerable
    get dateUpdated(): Date {
        return this.#metadata.timestampUpdated;
    }

    @Enumerable
    get friendlyName(): string {
        return this.#metadata.queueFriendlyName || "";
    }

    subscribe(callback: QueueStatsCallback) {
        this.#subscribers.push(callback);

        if (this.#subscribers.length === 1) {
            this.#metadata.subscribe(this.#itemUpdatedHandler);
        }
    }

    readonly #itemUpdatedHandler = () => {
        this.#subscribers.forEach((fn) => {
            fn(QueueStatsItemKey.Metadata);
        });
    };

    unsubscribe(callback: QueueStatsCallback) {
        const subscriberIndex = this.#subscribers.indexOf(callback);
        if (subscriberIndex >= 0) {
            this.#subscribers.splice(subscriberIndex, 1);
        }
        if (this.#subscribers.length === 0) {
            this.#metadata.unsubscribe(this.#itemUpdatedHandler);
        }
    }

    async getWorkerStats(): Promise<Subscribable<WorkerStats>> {
        try {
            const workerStats = await this.#queueStatsService.getWorkerStats(this.sid);
            const subscribableOptions: SubscribableOptions<keyof WorkerStats> = {
                mapKeysToCamelCase: true,
                dateFields: ["timestampUpdated"]
            };
            return workerStats.getSubscribable(subscribableOptions);
        } catch (error) {
            if (error.wrappedError?.code === InternalErrorCode.SyncNameNotFoundError) {
                return this.#rethrowErrorwithCustomFunction(error, `Worker statistics of the queue: ${this.sid}`);
            }
            const metadata = {
                module: extractModuleFromPath(__dirname),
                resourceSid: this.sid,
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            return this.#throwErrorFromErrorResponse(error, metadata);
        }
    }

    async getTaskChannelStats(): Promise<Subscribable<TaskChannelStats>> {
        return Promise.reject(new Error("Not implemented"));
    }

    async getAllTaskChannelStats(): Promise<Subscribable<TaskChannelStats>[]> {
        return Promise.reject(new Error("Not implemented"));
    }
}
