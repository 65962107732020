











import { inject, injectable } from "inversify";

import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI } from "~/modules/error";
import { SyncAdapter } from "~/backend/SyncAdapter/SyncAdapter";
import { syncAdapterRTTI } from "~/backend/backend.rtti";
import { SyncDocument, SyncList, SyncMap, SyncMapItem } from "~/modules/sync";
import { extractFileNameFromPath } from "~/utils/extractFromPath";

import { WorkspaceStats } from "../model/workspaceStats";

import { WorkspaceStatsService } from "./workspaceStats.serviceInterface";

@injectable()
export class WorkspaceStatsServiceImpl implements WorkspaceStatsService {
    private readonly syncAdapter: SyncAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(syncAdapterRTTI) syncAdapter: SyncAdapter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction
    ) {
        this.syncAdapter = syncAdapter;
        this.#throwError = throwError;
    }

    



    public async getWorkspaceStats(): Promise<SyncMapItem<WorkspaceStats>> {
        const syncObject = (await this.syncAdapter.getSyncObjectByPath(
            `/flex_insights/Maps/realtime_statistics_v1/Items/workspace`
        )) as SyncMapItem<WorkspaceStats>;
        return syncObject;
    }
}
