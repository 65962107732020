import { SubscribableOptions } from "./SubscribableOptions";
import { Subscribable } from "./Subscribable";
import { mapKeysToCamelCase } from "~/utils/mapKeys";
import { hasKey } from "~/utils/typeGuards";

function mapValue(value: object, options: SubscribableOptions): object {
    const newValue = options.mapKeysToCamelCase ? mapKeysToCamelCase(value) : value;

    if (options.dateFields) {
        options.dateFields.forEach((dateField) => {
            if (hasKey(newValue, dateField)) {
                const dateValue = newValue[dateField] as string;
                newValue[dateField] = dateValue ? new Date(dateValue) : undefined;
            }
        });
    }

    return newValue;
}

export function createSubscribable<T>(
    value: object,
    options: SubscribableOptions,
    subscribeFunction: Function,
    unsubscribeFunction: Function
): Subscribable<T> {
    const subscribable = {
        subscribe: subscribeFunction,
        unsubscribe: unsubscribeFunction,
        ...mapValue(value, options)
    };

    return subscribable as Subscribable<T>;
}
