import { interfaces } from "inversify";
import { FlexSdkError } from "~/modules/error/FlexSdkError/FlexSdkError";
import { ErrorResponse } from "~/modules/error/ThrowError/ErrorHelper";
import { LoggerFactory, loggerFactoryRTTI, LoggerName } from "~/modules/logger";
import { TelemetryErrorEvent, TelemetryEventGroup } from "~/modules/telemetry";
import { telemetrySdkErrorEventGroupRTTI } from "~/modules/telemetrySdkClient";

export async function reportError(container: interfaces.Container, error: FlexSdkError) {
    try {
        const errorEventGroup = container.get<TelemetryEventGroup<TelemetryErrorEvent>>(
            telemetrySdkErrorEventGroupRTTI
        );

        const wrappedError = error.wrappedError as ErrorResponse;

        await errorEventGroup.addErrorEvent({
            eventName: "error_reported",
            error: {
                code: error.code,
                message: error.message,
                module: error.metadata.module,
                reporter: "flex-sdk",
                resourceSid: error.metadata.resourceSid,
                severity: error.metadata.severity,
                source: error.metadata.source || "flex-sdk",
                stackTrace: error.stack,
                unhandled: error.metadata.unhandled,
                wrappedErrorCode: wrappedError?.code,
                wrappedErrorMessage: wrappedError?.message ?? undefined
            }
        });
    } catch (e) {
        const loggerFactory = container.get<LoggerFactory>(loggerFactoryRTTI);
        const logger = loggerFactory(LoggerName.Error);
        logger.debug("Failed to report the error", e);
    }
}
