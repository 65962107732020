import { injectable, inject } from "inversify";
import { SyncMap, MapMode, Sync, SyncProductId } from "~/modules/sync";
import { NewableSyncClient, SyncClient, SyncClientEvent, SyncClientState } from "~/modules/sync/SyncClient/SyncClient";
import { newableSyncClientRTTI, syncMapProviderRTTI } from "~/modules/sync/sync.rtti";
import {
    InternalError,
    ErrorCode,
    ThrowErrorFunction,
    ThrowErrorFromErrorResponseFunction,
    throwErrorRTTI,
    throwErrorFromErrorResponseRTTI,
    ErrorSeverity
} from "~/modules/error";
import { Logger, LoggerFactory, loggerFactoryRTTI, LoggerName } from "~/modules/logger";
import { twilsockRTTI, Twilsock, TwilsockEvent } from "~/modules/websocket";
import { SyncMapProvider } from "~/modules/sync/SyncMapProvider/SyncMapProvider";
import { SyncEvent } from "./SyncEvent";
import { Emitter, eventEmitterRTTI } from "~/modules/events";
import { EnvironmentConfig, environmentConfigRTTI } from "~/modules/config";
import { ClientOptions, clientOptionsRTTI } from "~/modules/client";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { DeepPartial } from "~/utils/DeepPartial";

@injectable()
export class SyncImpl implements Sync {
    #syncClient: SyncClient;

    readonly #NewableSyncClient: NewableSyncClient;

    readonly #logger: Logger;

    readonly #twilsock: Twilsock;

    readonly #environmentConfig: EnvironmentConfig;

    readonly #clientOptions: DeepPartial<ClientOptions>;

    readonly #syncMapProvider: SyncMapProvider;

    readonly #emitter: Emitter;

    readonly #throwError: ThrowErrorFunction;

    readonly #throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction;

    constructor(
        @inject(newableSyncClientRTTI) newableSyncClient: NewableSyncClient,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory,
        @inject(twilsockRTTI) twilsock: Twilsock,
        @inject(environmentConfigRTTI) environmentConfig: EnvironmentConfig,
        @inject(clientOptionsRTTI) clientOptions: DeepPartial<ClientOptions>,
        @inject(syncMapProviderRTTI) syncMapProvider: SyncMapProvider,
        @inject(eventEmitterRTTI) emitter: Emitter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction,
        @inject(throwErrorFromErrorResponseRTTI) throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction
    ) {
        this.#NewableSyncClient = newableSyncClient;
        this.#logger = getLogger(LoggerName.Sync);
        this.#twilsock = twilsock;
        this.#environmentConfig = environmentConfig;
        this.#clientOptions = clientOptions;
        this.#syncMapProvider = syncMapProvider;
        this.#emitter = emitter;
        this.#throwError = throwError;
        this.#throwErrorFromErrorResponse = throwErrorFromErrorResponse;
    }

    public isConnected(): boolean {
        return this.#syncClient?.connectionState === SyncClientState.Connected;
    }

    async connect(token: string, productId: SyncProductId): Promise<void> {
        if (this.#syncClient) {
            return;
        }

        const clientOptions = {
            region: this.#clientOptions.region || this.#environmentConfig.region,
            twilsockClient: this.#twilsock.getRawTwilsockClient(),
            productId
        };
        this.#syncClient = new this.#NewableSyncClient(token, clientOptions);
        await this.#waitUntilConnectedOrRejected();
        this.#listenOnDisconnectEvent();
    }

    #waitUntilConnectedOrRejected = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.#syncClient.connectionState === SyncClientState.Connected) {
                resolve();
            }

            const connectionStateHandler = (newState: SyncClientState) => {
                this.#logger.debug(`Connection state changed: ${newState}`);
                if (newState === SyncClientState.Connected) {
                    resolve();
                }

                if (newState === SyncClientState.Error) {
                    this.#syncClient.removeAllListeners();
                }

                if ([SyncClientState.Error, SyncClientState.Disconnected, SyncClientState.Denied].includes(newState)) {
                    const metadata = {
                        module: extractModuleFromPath(__dirname),
                        severity: ErrorSeverity.Error,
                        source: extractFileNameFromPath(__filename)
                    };
                    reject(this.#throwError(ErrorCode.ConnectionError, metadata));
                }
            };

            this.#syncClient.on(SyncClientEvent.ConnectionStateChanged, connectionStateHandler);
        });
    };

    #listenOnDisconnectEvent = (): void => {
        const disconnectHandler = async () => {
            try {
                await this.destroy();
            } catch (error) {
                const metadata = {
                    module: extractModuleFromPath(__dirname),
                    severity: ErrorSeverity.Error,
                    source: extractFileNameFromPath(__filename)
                };
                this.#throwErrorFromErrorResponse(error, metadata);
            }
        };
        this.#twilsock.on(TwilsockEvent.Disconnected, disconnectHandler);
    };

    async getMapById(mapId: string, mapMode: MapMode = MapMode.OpenExisting): Promise<SyncMap> {
        if (!this.#syncClient) {
            throw new InternalError("Sync client hasn't been initialized");
        }

        return this.#syncMapProvider(this.#syncClient, mapId, mapMode);
    }

    async destroy(): Promise<void> {
        this.#emitter.emit(SyncEvent.Destroyed);
        if (!this.#syncClient) {
            return;
        }
        await this.#syncClient.shutdown();
        this.#syncClient.removeAllListeners();
        this.#logger.debug("Sync client destroyed");
    }

    on(eventName: SyncEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.on(eventName, listener);
        return this;
    }

    removeListener(eventName: SyncEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.removeListener(eventName, listener);
        return this;
    }
}
