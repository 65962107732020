import { interfaces } from "inversify";
import { AccountConfig, AccountConfigData, accountConfigRTTI, configDataContainerRTTI } from "~/modules/config";
import { ConfigDataContainer } from "~/modules/config/AccountConfig/ConfigDataContainer";
import { ConfigurationService } from "~/backend/generated/Configuration/api/configuration.serviceInterface";
import { configurationServiceRTTI } from "~/backend/backend.rtti";




export const accountConfigProvider = async (container: interfaces.Container): Promise<AccountConfig> => {
    const dataContainer = container.get<ConfigDataContainer<AccountConfigData>>(configDataContainerRTTI);
    const configurationService = container.get<ConfigurationService>(configurationServiceRTTI);
    const newConfigData = await configurationService.fetchConfiguration();
    dataContainer.set(newConfigData);

    const accountConfig = container.get<AccountConfig>(accountConfigRTTI);
    return accountConfig;
};
