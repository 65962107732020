import { interfaces } from "inversify";

import { TelemetryServiceImpl } from "./api/telemetry.service";
import { TelemetryService } from "./api/telemetry.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<TelemetryService>("TelemetryService").to(TelemetryServiceImpl).inSingletonScope();
    }
}
