import { ContainerModule, interfaces } from "inversify";
import {
    throwErrorRTTI,
    throwErrorFromErrorResponseRTTI,
    throwErrorFromResponseRTTI,
    reThrowErrorWithCustomMessageRTTI
} from "~/modules/error/error.rtti";
import {
    ThrowErrorFunction,
    ThrowErrorFromErrorResponseFunction,
    ThrowErrorFromResponseFunction,
    ReThrowErrorWithCustomMessageFunction
} from "~/modules/error/ThrowError/ThrowErrorFunctions";
import {
    throwFlexSdkError,
    throwFlexSdkErrorFromErrorResponse,
    throwFlexSdkErrorFromResponse,
    reThrowFlexSdkErrorWithCustomMessage,
    ErrorResponse
} from "~/modules/error/ThrowError/ErrorHelper";
import { FlexSdkErrorMetadata } from "~/modules/error/FlexSdkErrorMetadata/FlexSdkErrorMetadata";

export const errorModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<ThrowErrorFunction>(throwErrorRTTI)
        .toDynamicValue((context: interfaces.Context) => {
            return (errorCode: number, metadata?: FlexSdkErrorMetadata, message?: string, error?: Error) => {
                return throwFlexSdkError(context.container, errorCode, metadata, message, error);
            };
        })
        .inSingletonScope();
    bind<ThrowErrorFromErrorResponseFunction>(throwErrorFromErrorResponseRTTI)
        .toDynamicValue((context: interfaces.Context) => {
            return (errorResponse: ErrorResponse, metadata?: FlexSdkErrorMetadata) => {
                return throwFlexSdkErrorFromErrorResponse(context.container, errorResponse, metadata);
            };
        })
        .inSingletonScope();
    bind<ThrowErrorFromResponseFunction>(throwErrorFromResponseRTTI)
        .toDynamicValue((context: interfaces.Context) => {
            return (response: Response, metadata?: FlexSdkErrorMetadata, message?: string) => {
                return throwFlexSdkErrorFromResponse(context.container, response, metadata, message);
            };
        })
        .inSingletonScope();
    bind<ReThrowErrorWithCustomMessageFunction>(reThrowErrorWithCustomMessageRTTI).toConstantValue(
        reThrowFlexSdkErrorWithCustomMessage
    );
});
