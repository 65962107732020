import { interfaces } from "inversify";
import { SyncMap as SyncSdkSyncMap } from "twilio-sync";
import { SyncMapItem } from "~/modules/sync";
import { newableSyncMapItemRTTI } from "~/modules/sync/sync.rtti";
import { NewableSyncMapItem } from "~/modules/sync/SyncMapItem/NewableSyncMapItem";
import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI } from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

export async function getSyncMapItem(
    container: interfaces.Container,
    syncSdkSyncMap: SyncSdkSyncMap,
    key: string
): Promise<SyncMapItem> {
    try {
        const sdkSyncMapItem = await syncSdkSyncMap.get(key);
        const SyncMapItemConstructor = container.get<NewableSyncMapItem>(newableSyncMapItemRTTI);
        const item = new SyncMapItemConstructor(syncSdkSyncMap, sdkSyncMapItem);
        return item;
    } catch (error) {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        const throwError = container.get<ThrowErrorFunction>(throwErrorRTTI);
        return throwError(error.code || ErrorCode.SDK, metadata, error.message, error);
    }
}
