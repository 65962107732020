import { assertNotEmptyString } from "~/utils/assert";
import { Subscribable, SubscribableOptions } from "~/modules/sync";
import { TasksByTime, CurrentTasksStats, HistoricalTasksStats, TimeSpan } from "~/modules/stats";

import { QueueStatsService } from "~/backend/generated/RealtimeQueueStats/api/queueStats.serviceInterface";
import {
    ErrorSeverity,
    InternalErrorCode,
    ReThrowErrorWithCustomMessageFunction,
    ThrowErrorFromErrorResponseFunction
} from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

const MAP_ITEM_PROPERTY_KEY_QUEUE = "queue";

export class TasksByTimeImpl implements TasksByTime {
    readonly #queueStatsService: QueueStatsService;

    readonly #queueSid: string;

    readonly #throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction;

    readonly #reThrowErrorWithCustomMessage: ReThrowErrorWithCustomMessageFunction;

    constructor(
        queueSid: string,
        queueStatsService: QueueStatsService,
        throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction,
        reThrowErrorWithCustomMessage: ReThrowErrorWithCustomMessageFunction
    ) {
        this.#queueSid = queueSid;
        this.#queueStatsService = queueStatsService;
        this.#throwErrorFromErrorResponse = throwErrorFromErrorResponse;
        this.#reThrowErrorWithCustomMessage = reThrowErrorWithCustomMessage;
    }

    async getCurrentTasks(): Promise<Subscribable<CurrentTasksStats>> {
        try {
            const item = await this.#queueStatsService.getCurrentTasksStats(this.#queueSid);
            const subscribableOptions: SubscribableOptions<keyof CurrentTasksStats> = {
                mapKeysToCamelCase: true,
                dateFields: ["timestampUpdated", "longestTaskWaitingFrom"]
            };
            const subscribable = item.getSubscribableForSubKey(MAP_ITEM_PROPERTY_KEY_QUEUE, subscribableOptions);
            return subscribable;
        } catch (error) {
            if (error.wrappedError?.code === InternalErrorCode.SyncNameNotFoundError) {
                this.#reThrowErrorWithCustomMessage(
                    error,
                    `Statistics for current tasks of the queue: ${this.#queueSid}`
                );
            }
            const metadata = {
                module: extractModuleFromPath(__dirname),
                resourceSid: this.#queueSid,
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };

            return this.#throwErrorFromErrorResponse(error, metadata);
        }
    }

    async getForPeriod(period: TimeSpan): Promise<Subscribable<HistoricalTasksStats>> {
        assertNotEmptyString(period, "period");
        try {
            const item = await this.#queueStatsService.getHistoricalStats(this.#queueSid, period);
            const subscribableOptions: SubscribableOptions<keyof HistoricalTasksStats> = {
                mapKeysToCamelCase: true,
                dateFields: ["timestampUpdated"]
            };
            const subscribable = item.getSubscribableForSubKey(MAP_ITEM_PROPERTY_KEY_QUEUE, subscribableOptions);
            return subscribable;
        } catch (error) {
            if (error.wrappedError?.code === InternalErrorCode.SyncNameNotFoundError) {
                this.#reThrowErrorWithCustomMessage(
                    error,
                    `Historical statistics for the period ${period} of the queue: ${this.#queueSid}`
                );
            } else if (error.wrappedError?.code === InternalErrorCode.SyncMapItemNotFoundError) {
                this.#reThrowErrorWithCustomMessage(
                    error,
                    `Historical statistics for the period ${period} of the queue: ${this.#queueSid} missing. 
                    Maybe no accepted or cancelled tasks during this period.`
                );
            }
            const metadata = {
                module: extractModuleFromPath(__dirname),
                resourceSid: this.#queueSid,
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };

            return this.#throwErrorFromErrorResponse(error, metadata);
        }
    }
}
