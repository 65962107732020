



export class InternalError extends Error {
    constructor(message: string = "Internal Error") {
        super(message);
        Object.setPrototypeOf(this, InternalError.prototype);
    }
}
