import { injectable } from "inversify";
import { AuthenticatorDataContainer } from "./AuthenticatorDataContainer";

@injectable()
export class AuthenticatorDataContainerImpl implements AuthenticatorDataContainer {
    #accountSid: string;

    get accountSid(): string {
        return this.#accountSid;
    }

    set accountSid(accountSid: string) {
        this.#accountSid = accountSid;
    }
}
