import cloneDeep from "lodash/cloneDeep";
import { SessionOptions } from "~/modules/session";

const defaultSessionOptions: SessionOptions = {
    autoUpdateToken: true
};

export function getDefaultSessionOptions(): SessionOptions {
    return cloneDeep(defaultSessionOptions);
}
