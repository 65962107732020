import { interfaces } from "inversify";

import { ConfigurationServiceImpl } from "./api/configuration.service";
import { ConfigurationService } from "./api/configuration.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<ConfigurationService>("ConfigurationService").to(ConfigurationServiceImpl).inSingletonScope();
    }
}
