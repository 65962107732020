import { injectable, inject } from "inversify";
import { ClientOptions } from "~/modules/client";
import { SessionOptions, sessionOptionsRTTI } from "~/modules/session";
import { LoggerOptions, loggerOptionsRTTI } from "~/modules/logger";
import { TelemetryOptions, telemetryOptionsRTTI } from "~/modules/telemetry";

@injectable()
export class ClientOptionsStore implements ClientOptions {
    readonly #session: SessionOptions;

    readonly #logger: LoggerOptions;

    readonly #telemetry: TelemetryOptions;

    
    
    #region: string;

    #appName: string;

    #appVersion: string;

    constructor(
        @inject(sessionOptionsRTTI) session: SessionOptions,
        @inject(loggerOptionsRTTI) logger: LoggerOptions,
        @inject(telemetryOptionsRTTI) telemetry: TelemetryOptions
    ) {
        this.#session = session;
        this.#logger = logger;
        this.#telemetry = telemetry;
    }

    get session(): SessionOptions {
        return this.#session;
    }

    get logger(): LoggerOptions {
        return this.#logger;
    }

    get telemetry(): TelemetryOptions {
        return this.#telemetry;
    }

    get region(): string {
        return this.#region;
    }

    set region(value: string) {
        this.#region = value;
    }

    get appName(): string {
        return this.#appName;
    }

    set appName(value: string) {
        this.#appName = value;
    }

    get appVersion(): string {
        return this.#appVersion;
    }

    set appVersion(value: string) {
        this.#appVersion = value;
    }
}
