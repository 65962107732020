import { ContainerModule, interfaces } from "inversify";
import { sessionRTTI, sessionOptionsRTTI } from "./session.rtti";
import { Session, SessionOptions } from "./Session/Session";
import { SessionImpl } from "./Session/SessionImpl";
import { getDefaultSessionOptions } from "./SessionOptions/defaultSessionOptions";

export const sessionModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<Session>(sessionRTTI).to(SessionImpl).inSingletonScope();
    bind<SessionOptions>(sessionOptionsRTTI).toConstantValue(getDefaultSessionOptions());
});
