import { ContainerModule, interfaces } from "inversify";
import {
    authenticatorRTTI,
    authenticatorFactoryRTTI,
    twilioIdpUrlRTTI,
    authenticatorDataContainerRTTI
} from "./auth.rtti";
import { Authenticator } from "./Authenticator/Authenticator";
import { FederatedAuth } from "./Authenticator/FederatedAuth/FederatedAuth";
import { AuthenticatorFactory } from "./AuthenticatorFactory/AuthenticatorFactory";
import { createAuthenticator } from "./AuthenticatorFactory/createAuthenticator/createAuthenticator";
import { TwilioIdpUrlFunction } from "./TwilioIdpUrlFunction/TwilioIdpUrl";
import { getTwilioIdpUrl } from "./TwilioIdpUrlFunction/getTwilioIdpUrl";
import { AuthenticatorDataContainer } from "./AuthenticatorDataContainer/AuthenticatorDataContainer";
import { AuthenticatorDataContainerImpl } from "./AuthenticatorDataContainer/AuthenticatorDataContainerImpl";

export const authModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<Authenticator>(authenticatorRTTI).to(FederatedAuth);
    bind<AuthenticatorDataContainer>(authenticatorDataContainerRTTI)
        .to(AuthenticatorDataContainerImpl)
        .inSingletonScope();
    bind<AuthenticatorFactory>(authenticatorFactoryRTTI).toFactory((context: interfaces.Context) => {
        return (accountSid: string) => {
            return createAuthenticator(context.container, accountSid);
        };
    });
    bind<TwilioIdpUrlFunction>(twilioIdpUrlRTTI).toDynamicValue((context: interfaces.Context) => {
        return (port?: string) => {
            return getTwilioIdpUrl(context.container, port);
        };
    });
});
