import { injectable, inject } from "inversify";
import { TelemetryClient, TelemetryEvent, TelemetryEventGroup } from "~/modules/telemetry";
import { TelemetryProcessor } from "~/modules/telemetry/TelemetryProcessor/TelemetryProcessor";
import { telemetryProcessorRTTI, telemetryOptionsRTTI } from "~/modules/telemetry/telemetry.rtti";
import { TelemetryProcessingResult } from "~/modules/telemetry/TelemetryProcessor/TelemetryProcessingResult";
import { TelemetryErrorEvent } from "~/modules/telemetry/TelemetryEventGroup/TelemetryErrorEvent";
import { TelemetryOptions } from "../../TelemetryOptions/TelemetryOptions";
import { Twilsock, twilsockRTTI } from "~/modules/websocket";

@injectable()
export class TelemetryEventGroupImpl<T> implements TelemetryEventGroup<T> {
    name?: string;

    telemetryClient: TelemetryClient;

    readonly #processor: TelemetryProcessor;

    readonly #options: TelemetryOptions;

    readonly #connection: Twilsock;

    constructor(
        @inject(telemetryProcessorRTTI) processor: TelemetryProcessor,
        @inject(telemetryOptionsRTTI) telemetryOptions: TelemetryOptions,
        @inject(twilsockRTTI) twilsock: Twilsock
    ) {
        this.#processor = processor;
        this.#options = telemetryOptions;
        this.#connection = twilsock;
    }

    async addEvents(...events: (T & TelemetryEvent)[]): Promise<TelemetryProcessingResult> {
        return this.#processor.processEvents(
            this.telemetryClient.name,
            this.name,
            this.telemetryClient.sessionData,
            ...events
        );
    }

    async addErrorEvent(errorEvent: TelemetryErrorEvent): Promise<TelemetryProcessingResult> {
        const isWebsocketAvailable = this.#connection.isConnected();
        const suppressErrors = !(this.#options.reportErrors && isWebsocketAvailable);

        if (suppressErrors) {
            return {
                eventsSucceeded: 0,
                eventsFailed: 0,
                eventsNotProcessed: 1
            };
        }

        return this.#processor.processEvents(
            "flex-ui-sdk-error-v1",
            this.name,
            this.telemetryClient.sessionData,
            errorEvent
        );
    }
}
