import { interfaces } from "inversify";
import { AccountConfigData } from "~/modules/config";
import { ConfigurationService } from "~/backend/generated/Configuration/api/configuration.serviceInterface";
import { configurationServiceRTTI } from "~/backend/backend.rtti";

export const updateAccountConfig = async (
    container: interfaces.Container,
    accountSid: string,
    config: Partial<AccountConfigData>
): Promise<AccountConfigData> => {
    const configurationService = container.get<ConfigurationService>(configurationServiceRTTI);
    const updatedConfiguration = await configurationService.updateConfiguration({ accountSid, ...config });
    return updatedConfiguration;
};
