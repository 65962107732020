import { injectable, inject } from "inversify";
import { telemetryEventGroupFactoryRTTI } from "~/modules/telemetry/telemetry.rtti";
import { Logger, loggerFactoryRTTI, LoggerFactory, TelemetryLoggerName } from "~/modules/logger";
import { TelemetryEventGroup, TelemetryClient } from "~/modules/telemetry";
import { TelemetryEventGroupFactory } from "~/modules/telemetry/TelemetryEventGroupFactory/TelemetryEventGroupFactory";

@injectable()
export class TelemetryClientImpl<U extends object> implements TelemetryClient<U> {
    name: string;

    sessionData?: U;

    readonly #eventGroupFactory: TelemetryEventGroupFactory<unknown>;

    readonly #logger: Logger;

    constructor(
        @inject(telemetryEventGroupFactoryRTTI) eventGroupFactory: TelemetryEventGroupFactory<unknown>,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory<TelemetryLoggerName>
    ) {
        this.#eventGroupFactory = eventGroupFactory;
        this.#logger = getLogger(TelemetryLoggerName.TelemetryClient);
    }

    createEventGroup<T>(name?: string): TelemetryEventGroup<T> {
        this.#logger.debug("creating event group with name: ", name);
        const eventGroup = this.#eventGroupFactory(this, name);
        return eventGroup;
    }

    
    
    async setSessionData(sessionData: U): Promise<void> {
        this.sessionData = sessionData;
    }
}
