import { Emitter } from "~/modules/events/EventPublisher/Emitter";




export interface EventPublisher<E extends string | symbol> {
    on(eventName: E, listener: (...args: unknown[]) => void): this;
    removeListener(eventName: E, listener: (...args: unknown[]) => void): this;
}

export function proxyEvent<T extends string | symbol, U extends string | symbol>(
    from: EventPublisher<T>,
    to: Emitter,
    event: T,
    alias: U
) {
    from.on(event, (...args: unknown[]) => {
        to.emit(alias, ...args);
    });
}
