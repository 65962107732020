import { interfaces } from "inversify";
import { Authenticator, authenticatorRTTI } from "~/modules/auth";
import { authenticatorDataContainerRTTI } from "~/modules/auth/auth.rtti";
import { AuthenticatorDataContainer } from "~/modules/auth/AuthenticatorDataContainer/AuthenticatorDataContainer";

export function createAuthenticator(container: interfaces.Container, accountSid: string): Authenticator {
    const authenticatorDataContainer = container.get<AuthenticatorDataContainer>(authenticatorDataContainerRTTI);
    authenticatorDataContainer.accountSid = accountSid;

    const auth = container.get<Authenticator>(authenticatorRTTI);
    return auth;
}
