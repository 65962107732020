import { inject, injectable } from "inversify";
import { QueueStats, RealtimeStats } from "~/modules/stats";
import {
    ErrorCode,
    InternalErrorCode,
    ReThrowErrorWithCustomMessageFunction,
    reThrowErrorWithCustomMessageRTTI,
    ThrowErrorFunction,
    throwErrorRTTI,
    ThrowErrorFromErrorResponseFunction,
    throwErrorFromErrorResponseRTTI,
    ErrorSeverity
} from "~/modules/error";
import { queueStatsProviderRTTI } from "~/modules/stats/stats.rtti";
import { rbacRTTI, Rbac } from "~/modules/rbac";
import { QueueStatsProvider } from "~/modules/stats/RealtimeStats/QueueStatsProvider/QueueStatsProvider";
import { Subscribable, SubscribableOptions } from "~/modules/sync";
import { workspaceStatsServiceRTTI } from "~/backend/backend.rtti";
import { WorkspaceStats } from "~/backend/generated/RealtimeQueueStats/model/workspaceStats";
import { WorkspaceStatsService } from "~/backend/generated/RealtimeQueueStats/api/workspaceStats.serviceInterface";
import { QueueStatsCallback } from "~/modules/stats/RealtimeStats/QueueStats/QueueStats";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

@injectable()
export class RealtimeStatsImpl implements RealtimeStats {
    readonly #getQueueStats: QueueStatsProvider;

    readonly #rbac: Rbac;

    readonly #workspaceStatsService: WorkspaceStatsService;

    readonly #throwError: ThrowErrorFunction;

    readonly #throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction;

    readonly #reThrowErrorWithCustomMessage: ReThrowErrorWithCustomMessageFunction;

    constructor(
        @inject(queueStatsProviderRTTI) getQueueStats: QueueStatsProvider,
        @inject(rbacRTTI) rbac: Rbac,
        @inject(workspaceStatsServiceRTTI) workspaceStatsService: WorkspaceStatsService,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction,
        @inject(throwErrorFromErrorResponseRTTI) throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction,
        @inject(reThrowErrorWithCustomMessageRTTI) reThrowErrorWithCustomMessage: ReThrowErrorWithCustomMessageFunction
    ) {
        this.#getQueueStats = getQueueStats;
        this.#rbac = rbac;
        this.#workspaceStatsService = workspaceStatsService;
        this.#throwError = throwError;
        this.#reThrowErrorWithCustomMessage = reThrowErrorWithCustomMessage;
        this.#throwErrorFromErrorResponse = throwErrorFromErrorResponse;
    }

    #validatePermissions = () => {
        const isSupervisorOrAdmin: boolean = this.#rbac.hasRole("supervisor") || this.#rbac.hasRole("admin");
        if (!isSupervisorOrAdmin) {
            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            this.#throwError(ErrorCode.PermissionDeniedError, metadata);
        }
    };

    async getQueueStatsBySid(queueSid: string): Promise<Subscribable<QueueStats, QueueStatsCallback>> {
        this.#validatePermissions();
        const queueStats = await this.#getQueueStats(queueSid);
        return queueStats;
    }

    async getWorkspaceStats(): Promise<Subscribable<WorkspaceStats>> {
        try {
            const workspaceStats = await this.#workspaceStatsService.getWorkspaceStats();
            const subscribableOptions: SubscribableOptions = {
                mapKeysToCamelCase: true,
                dateFields: ["workspaceModified, longestTaskWaitingFrom", "timestamp"]
            };
            return workspaceStats.getSubscribable(subscribableOptions);
        } catch (error) {
            if (error.wrappedError?.code === InternalErrorCode.SyncNameNotFoundError) {
                this.#reThrowErrorWithCustomMessage(error, "Workspace statistics");
            }
            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            return this.#throwErrorFromErrorResponse(error, metadata);
        }
    }
}
