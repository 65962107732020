import { ContainerModule, interfaces } from "inversify";
import { SyncClient as SyncSdkClient, SyncMap as SyncSdkSyncMap } from "twilio-sync";
import {
    newableSyncClientRTTI,
    syncRTTI,
    syncMapProviderRTTI,
    newableSyncMapRTTI,
    newableSyncMapItemRTTI,
    syncMapItemProviderRTTI,
    syncProviderRTTI,
    syncCacheRTTI
} from "./sync.rtti";
import { MapMode } from "./Sync/MapMode";
import { NewableSyncClient, SyncClient } from "./SyncClient/SyncClient";
import { Sync } from "./Sync/Sync";
import { SyncImpl } from "./Sync/SyncImpl";
import { SyncMapProvider } from "./SyncMapProvider/SyncMapProvider";
import { SyncMapImpl } from "./SyncMap/SyncMapImpl";
import { SyncMapItemProvider } from "./SyncMapItemProvider/SyncMapItemProvider";
import { SyncMapItemImpl } from "./SyncMapItem/SyncMapItemImpl";
import { NewableSyncMap } from "./SyncMap/NewableSyncMap";
import { NewableSyncMapItem } from "./SyncMapItem/NewableSyncMapItem";
import { getSyncMap } from "./SyncMapProvider/getSyncMap";
import { getSyncMapItem } from "./SyncMapItemProvider/getSyncMapItem";
import { SyncProvider } from "./SyncProvider/SyncProvider";
import { getSync } from "./SyncProvider/getSync";
import { SyncProductId } from "./SyncProvider/SyncProductId";

export const syncModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<NewableSyncClient>(newableSyncClientRTTI).toConstructor(SyncClient);
    bind<Sync>(syncRTTI).to(SyncImpl);
    bind<NewableSyncMap>(newableSyncMapRTTI).toConstructor(SyncMapImpl);
    bind<NewableSyncMapItem>(newableSyncMapItemRTTI).toConstructor(SyncMapItemImpl);

    bind<SyncMapProvider>(syncMapProviderRTTI).toProvider((context: interfaces.Context) => {
        return (syncSdkClient: SyncSdkClient, mapId: string, mapMode: MapMode) => {
            return getSyncMap(context.container, syncSdkClient, mapId, mapMode);
        };
    });
    bind<SyncMapItemProvider>(syncMapItemProviderRTTI).toProvider((context: interfaces.Context) => {
        return (syncSdkSyncMap: SyncSdkSyncMap, key: string) => {
            return getSyncMapItem(context.container, syncSdkSyncMap, key);
        };
    });
    bind<SyncProvider>(syncProviderRTTI).toProvider<Sync>((context: interfaces.Context) => {
        return (syncProductId: SyncProductId) => {
            return getSync(context.container, syncProductId);
        };
    });
    bind<Map<SyncProductId, Sync>>(syncCacheRTTI).toConstantValue(new Map());
});
