import { interfaces } from "inversify";

import { QueueStatsServiceImpl } from "./api/queueStats.service";
import { QueueStatsService } from "./api/queueStats.serviceInterface";
import { WorkspaceStatsServiceImpl } from "./api/workspaceStats.service";
import { WorkspaceStatsService } from "./api/workspaceStats.serviceInterface";

export class ApiServiceBinder {
    public static with(container: interfaces.Container) {
        container.bind<QueueStatsService>("QueueStatsService").to(QueueStatsServiceImpl).inSingletonScope();
        container.bind<WorkspaceStatsService>("WorkspaceStatsService").to(WorkspaceStatsServiceImpl).inSingletonScope();
    }
}
