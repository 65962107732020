import { injectable, inject } from "inversify";
import loglevel from "loglevel";
import { version } from "package.json";
import { proxyEvent, eventEmitterRTTI, Emitter } from "~/modules/events";
import { TwilsockClient, TwilsockClientEvent } from "~/modules/websocket/TwilsockClient/TwilsockClient";
import { productIdRTTI, twilsockClientFactoryRTTI } from "~/modules/websocket/websocket.rtti";
import { Headers, Twilsock, TwilsockResult, TwilsockEvent } from "~/modules/websocket";
import {
    ErrorCode,
    FlexSdkError,
    throwErrorRTTI,
    throwErrorFromErrorResponseRTTI,
    ThrowErrorFunction,
    ThrowErrorFromErrorResponseFunction,
    InternalError,
    ErrorSeverity
} from "~/modules/error";
import { Logger, loggerFactoryRTTI, LoggerFactory, LoggerName, LoglevelMethodName } from "~/modules/logger";
import { environmentConfigRTTI, EnvironmentConfig } from "~/modules/config";
import { ClientOptions, clientOptionsRTTI } from "~/modules/client";
import { retry } from "~/utils/retry";
import { TwilsockClientFactory } from "~/modules/websocket/TwilsockClientFactory/TwilsockClientFactory";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { DeepPartial } from "~/utils/DeepPartial";

const FLEX_SDK_NAME = "flex-sdk";
const FLEX_SDK_PLATFORM = "JS";

@injectable()
export class TwilsockImpl implements Twilsock {
    readonly #productId: string;

    readonly #twilsockClientFactory: TwilsockClientFactory;

    
    
    private twilsockClient?: TwilsockClient;

    readonly #logger: Logger;

    readonly #environmentConfig: EnvironmentConfig;

    readonly #clientOptions: DeepPartial<ClientOptions>;

    readonly #emitter: Emitter;

    readonly #throwError: ThrowErrorFunction;

    readonly #throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction;

    constructor(
        @inject(twilsockClientFactoryRTTI) twilsockClientFactory: TwilsockClientFactory,
        @inject(productIdRTTI) productId: string,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory,
        @inject(environmentConfigRTTI) environmentConfig: EnvironmentConfig,
        @inject(clientOptionsRTTI) clientOptions: DeepPartial<ClientOptions>,
        @inject(eventEmitterRTTI) emitter: Emitter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction,
        @inject(throwErrorFromErrorResponseRTTI) throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction
    ) {
        this.#twilsockClientFactory = twilsockClientFactory;
        this.#productId = productId;
        this.#logger = getLogger(LoggerName.Twilsock);
        this.#logger.debug("Twilsock constructed");
        this.#environmentConfig = environmentConfig;
        this.#clientOptions = clientOptions;
        this.#emitter = emitter;

        this.#throwError = throwError;
        this.#throwErrorFromErrorResponse = throwErrorFromErrorResponse;
    }

    async connect(token: string): Promise<void> {
        if (this.twilsockClient) {
            throw new InternalError("Twilsock connection already exists");
        }
        const clientOptions = {
            region: this.#clientOptions.region || this.#environmentConfig.region,
            clientMetadata: {
                type: FLEX_SDK_NAME,
                sdk: FLEX_SDK_PLATFORM,
                sdkv: version,
                app: this.#clientOptions.appName,
                appv: this.#clientOptions.appVersion
            }
        };
        this.twilsockClient = this.#twilsockClientFactory(token, this.#productId, clientOptions);
        this.#proxyEventsFromTwilsockClient();
        this.#proxyLogsFromTwilsockClient();
        this.twilsockClient.connect();
        await this.#waitUntilConnectedOrRejected();
    }

    #proxyEventsFromTwilsockClient = () => {
        proxyEvent(
            this.getRawTwilsockClient(),
            this.#emitter,
            TwilsockClientEvent.TokenExpired,
            TwilsockEvent.TokenExpired
        );
        proxyEvent(
            this.getRawTwilsockClient(),
            this.#emitter,
            TwilsockClientEvent.TokenAboutToExpire,
            TwilsockEvent.TokenAboutToExpire
        );
        proxyEvent(
            this.getRawTwilsockClient(),
            this.#emitter,
            TwilsockClientEvent.StateChanged,
            TwilsockEvent.StateChanged
        );
        proxyEvent(this.getRawTwilsockClient(), this.#emitter, TwilsockClientEvent.Connected, TwilsockEvent.Connected);
        proxyEvent(
            this.getRawTwilsockClient(),
            this.#emitter,
            TwilsockClientEvent.Disconnected,
            TwilsockEvent.Disconnected
        );
        this.#listenAndEmitConnectionError();
    };

    #proxyLogsFromTwilsockClient = () => {
        const twilsockLogger = loglevel.getLogger("twilsock");
        twilsockLogger.methodFactory = (methodName: LoglevelMethodName) => (...messages: unknown[]) => {
            return this.#logger[methodName](...messages);
        };
        twilsockLogger.setLevel("trace");
    };

    #listenAndEmitConnectionError = (): void => {
        this.getRawTwilsockClient().on(TwilsockClientEvent.ConnectionError, ({ errorCode, metadata, message }) => {
            const flexError = new FlexSdkError(errorCode || ErrorCode.TwilsockConnectionError, metadata, message);
            this.#emitter.emit(TwilsockEvent.ConnectionError, flexError);
        });
    };

    async updateToken(token: string): Promise<void> {
        if (!this.twilsockClient) {
            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };

            this.#throwError(ErrorCode.InvalidState, metadata, "no twilsock client");
        } else {
            try {
                await this.twilsockClient.updateToken(token);
                this.#emitter.emit(TwilsockEvent.TokenUpdated, token);
            } catch (error) {
                const metadata = {
                    module: extractModuleFromPath(__dirname),
                    severity: ErrorSeverity.Error,
                    source: "update Twilsock token"
                };

                this.#throwErrorFromErrorResponse(error, metadata);
            }
        }
    }

    #waitUntilConnectedOrRejected = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.getRawTwilsockClient().isConnected) {
                resolve();
                return;
            }

            const successHandler = () => {
                return resolve();
            };

            const connectionErrorHandler = (error: FlexSdkError) => {
                return reject(error);
            };

            const removeConnectionListeners = () => {
                this.removeListener(TwilsockEvent.Connected, successHandler);
                this.removeListener(TwilsockEvent.ConnectionError, connectionErrorHandler);
            };

            this.on(TwilsockEvent.Connected, () => {
                removeConnectionListeners();
                successHandler();
            });
            this.on(TwilsockEvent.ConnectionError, (error: FlexSdkError) => {
                removeConnectionListeners();
                connectionErrorHandler(error);
            });
        });
    };

    getRawTwilsockClient(): TwilsockClient {
        if (!this.twilsockClient) {
            throw new InternalError("Twilsock hasn't been initialized");
        }
        return this.twilsockClient;
    }

    async post<T>(url: string, headers: Headers, body: object): Promise<TwilsockResult<T>> {
        try {
            return await retry<TwilsockResult<T>>(
                () => this.getRawTwilsockClient().post(url, headers, body),
                this.#logger
            );
        } catch (error) {
            const code: number = error.body?.code || ErrorCode.SDK;
            const message: string = error.body?.message || error.message;
            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };

            return this.#throwError(code, metadata, message, error);
        }
    }

    async destroy(): Promise<void> {
        if (!this.twilsockClient) {
            return;
        }
        const twilsockClient = this.twilsockClient;
        const connectionDestroyed = new Promise((resolve) => {
            twilsockClient.on(TwilsockClientEvent.Disconnected, resolve);
        });
        await twilsockClient.disconnect();
        await connectionDestroyed;

        
        
        delete this.twilsockClient;
        this.#emitter.removeAllListeners();
    }

    on(eventName: TwilsockEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.on(eventName, listener);
        return this;
    }

    removeListener(eventName: TwilsockEvent, listener: (...args: unknown[]) => void): this {
        this.#emitter.removeListener(eventName, listener);
        return this;
    }

    isConnected(): boolean {
        if (!this.twilsockClient) {
            return false;
        }

        return this.getRawTwilsockClient().isConnected;
    }
}
