












import { inject, injectable } from "inversify";
import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI } from "~/modules/error";
import { twilsockAdapterRTTI } from "~/backend/backend.rtti";
import { TwilsockAdapter } from "~/backend/TwilsockAdapter/TwilsockAdapter";
import { TwilsockResult } from "~/modules/websocket";
import { extractFileNameFromPath } from "~/utils/extractFromPath";

import { Events } from "../model/events";
import { ProcessingStats } from "../model/processingStats";

import { TelemetryService } from "./telemetry.serviceInterface";

@injectable()
export class TelemetryServiceImpl implements TelemetryService {
    private basePath: string = "http://flex-sdk.twilio.com/v1";

    private twilsockAdapter: TwilsockAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(twilsockAdapterRTTI) twilsockAdapter: TwilsockAdapter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction
    ) {
        this.twilsockAdapter = twilsockAdapter;
        this.#throwError = throwError;
    }

    




    public async postTelemetryEvents(events: Events): Promise<TwilsockResult<ProcessingStats>> {
        if (events === null || events === undefined) {
            const metadata = {
                module: "backend",
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            this.#throwError(
                ErrorCode.InvalidParams,
                metadata,
                "Required parameter events was null or undefined when calling postTelemetryEvents."
            );
        }

        const response = await this.twilsockAdapter.post<ProcessingStats>(`${this.basePath}/Telemetry/Events`, events);
        return response;
    }
}
