import { injectable, inject } from "inversify";
import { TelemetryProcessor } from "~/modules/telemetry/TelemetryProcessor/TelemetryProcessor";
import { TelemetryProcessingResult } from "~/modules/telemetry/TelemetryProcessor/TelemetryProcessingResult";
import { TelemetryEvent } from "~/modules/telemetry";
import { Logger, loggerFactoryRTTI, LoggerFactory, TelemetryLoggerName } from "~/modules/logger";
import { toSdkBackendEvents } from "./toSdkBackendEvent";
import { TelemetryBackendEvent } from "~/backend/generated/Telemetry/model/telemetryBackendEvent";
import { ProcessingStats } from "~/backend/generated/Telemetry/model/processingStats";
import { TelemetryService } from "~/backend/generated/Telemetry/api/telemetry.serviceInterface";
import { telemetryServiceRTTI } from "~/backend/backend.rtti";
import {
    ErrorSeverity,
    ThrowErrorFromErrorResponseFunction,
    throwErrorFromErrorResponseRTTI,
    ThrowErrorFunction,
    throwErrorRTTI
} from "~/modules/error";
import { assertNotEmptyString } from "~/utils/assert";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

const TELEMETRY_DISABLED_HTTP_STATUS_CODE = 409;
const MAX_NUMBER_OF_EVENTS_IN_BATCH = 50;

@injectable()
export class TwilioTelemetryProcessor implements TelemetryProcessor {
    readonly #logger: Logger;

    readonly #telemetryService: TelemetryService;

    #isTelemetryDisabled = false;

    readonly #throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory<TelemetryLoggerName>,
        @inject(telemetryServiceRTTI) telemetryService: TelemetryService,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction,
        @inject(throwErrorFromErrorResponseRTTI) throwErrorFromErrorResponse: ThrowErrorFromErrorResponseFunction
    ) {
        this.#logger = getLogger(TelemetryLoggerName.TelemetryProcessor);
        this.#telemetryService = telemetryService;
        this.#throwError = throwError;
        this.#throwErrorFromErrorResponse = throwErrorFromErrorResponse;
    }

    async processEvents(
        payloadType: string,
        groupName?: string,
        sessionData?: object,
        ...events: TelemetryEvent[]
    ): Promise<TelemetryProcessingResult> {
        assertNotEmptyString(payloadType, "payload type");

        if (typeof groupName !== "undefined") {
            assertNotEmptyString(groupName, "group name");
        }

        events.forEach(({ eventName, eventSource }) => {
            assertNotEmptyString(eventName, "event name");
            if (typeof eventSource !== "undefined") {
                assertNotEmptyString(eventSource, "event source");
            }
        });

        if (this.#isTelemetryDisabled) {
            this.#logger.trace("Events not sent: telemetry disabled");
            return {
                eventsNotProcessed: events.length,
                eventsSucceeded: 0,
                eventsFailed: 0
            };
        }
        this.#logger.debug("common attributes:", sessionData);
        const backendEvents = toSdkBackendEvents(this.#throwError, payloadType, groupName, sessionData, ...events);

        let eventsSucceeded = 0;
        let eventsFailed = 0;

        if (backendEvents.length) {
            let backendEventsBatch;
            const arrayOfPromises = [];
            for (let i = 0; i < backendEvents.length; i += MAX_NUMBER_OF_EVENTS_IN_BATCH) {
                backendEventsBatch = backendEvents.slice(i, i + MAX_NUMBER_OF_EVENTS_IN_BATCH);
                arrayOfPromises.push(this.#sendTelemetryEvents(...backendEventsBatch));
            }
            const batchResults = await Promise.all(arrayOfPromises);
            eventsSucceeded = batchResults.reduce((acc, batch) => acc + batch.number_of_successful_events, 0);
            eventsFailed = batchResults.reduce((acc, batch) => acc + batch.number_of_failed_events, 0);
        }

        const eventsNotProcessed = events.length - eventsSucceeded - eventsFailed;

        return {
            eventsSucceeded,
            eventsFailed,
            eventsNotProcessed
        };
    }

    #sendTelemetryEvents = async (...events: TelemetryBackendEvent[]): Promise<ProcessingStats> => {
        this.#logger.debug("Sending", events.length, "telemetry events");
        this.#logger.trace("Events", events);

        let stats: ProcessingStats = {
            number_of_successful_events: 0,
            number_of_failed_events: 0
        };

        try {
            const { body } = await this.#telemetryService.postTelemetryEvents({ events });

            if (body) {
                stats = body;
                this.#logger.debug("Telemetry sent successfully");
            }
        } catch (error) {
            const httpErrorCode = error.wrappedError?.status;
            if (httpErrorCode !== TELEMETRY_DISABLED_HTTP_STATUS_CODE) {
                const metadata = {
                    module: extractModuleFromPath(__dirname),
                    severity: ErrorSeverity.Error,
                    eventSource: extractFileNameFromPath(__filename)
                };

                this.#throwErrorFromErrorResponse(error, metadata);
            }
            this.#logger.warn("Telemetry is disabled for this account");
            this.#isTelemetryDisabled = true;
        }

        return stats;
    };
}
