import { ErrorCode, ErrorSeverity, FlexSdkError } from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

function hasCycles(object: object): boolean {
    try {
        JSON.stringify(object);
    } catch (err) {
        const hasCycle = /^TypeError: Converting circular structure to JSON/.test(err);
        return hasCycle;
    }

    return false;
}

export function assertArray<T>(value: Array<T>, name: string): asserts value is Array<T> {
    if (!Array.isArray(value)) {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${name}, expected an array`);
    }
}

export function assertBoolean(value: boolean, name: string): asserts value is boolean {
    if (typeof value !== "boolean") {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${name}, expected a boolean`);
    }
}

export function assertDefined<T>(value: T, name: string): asserts value is T {
    if (typeof value === "undefined") {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${name}, expected a defined value`);
    }
}

export function assertNumber(value: number, name: string): asserts value is number {
    if (typeof value !== "number" || Number.isNaN(value)) {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${name}, expected a number`);
    }
}

export function assertObject<T>(value: T, name: string): asserts value is T {
    if (typeof value !== "object" || value === null || Array.isArray(value)) {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${name}, expected an object`);
    }
}

export function assertNotCircularObject<T extends object>(value: T, name: string): asserts value is T {
    assertObject(value, name);
    if (hasCycles(value)) {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${name}, expected an object without cycles`);
    }
}

export function assertString(value: string, name: string): asserts value is string {
    if (typeof value !== "string") {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${name}, expected a string`);
    }
}

export function assertNotEmptyString(value: string, name: string): asserts value is string {
    assertString(value, name);
    if (value.trim() === "") {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${name}, expected a string which is not empty`);
    }
}

export function assertKeyInObject<T>(key: string, object: T): void {
    if (!(key in object)) {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${key} is not a valid key`);
    }
}
