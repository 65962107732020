import { ContainerModule, interfaces } from "inversify";
import {
    telemetryClientFactoryRTTI,
    telemetryEventGroupFactoryRTTI,
    telemetryClientRTTI,
    telemetryEventGroupRTTI,
    telemetryProcessorRTTI,
    telemetryOptionsRTTI
} from "./telemetry.rtti";
import {
    createTelemetryClient,
    TelemetryClient,
    TelemetryClientFactory,
    TelemetryEventGroup
} from "~/modules/telemetry";
import { TelemetryProcessor } from "./TelemetryProcessor/TelemetryProcessor";
import { createEventGroup } from "./TelemetryEventGroupFactory/createEventGroup";
import { TelemetryClientImpl } from "./TelemetryClient/TelemetryClientImpl/TelemetryClientImpl";
import { TelemetryEventGroupImpl } from "./TelemetryEventGroup/TelemetryEventGroupImpl/TelemetryEventGroupImpl";
import { TelemetryEventGroupFactory } from "./TelemetryEventGroupFactory/TelemetryEventGroupFactory";
import { TwilioTelemetryProcessor } from "./TelemetryProcessor/TwilioEventProcessor/TwilioTelemetryProcessor";
import { TelemetryOptions } from "./TelemetryOptions/TelemetryOptions";
import { getDefaultTelemetryOptions } from "./TelemetryOptions/getDefaultTelemetryOptions";

export const telemetryModuleContainer = new ContainerModule(<U extends object>(bind: interfaces.Bind) => {
    bind<TelemetryClientFactory<U>>(telemetryClientFactoryRTTI).toFactory((context: interfaces.Context) => {
        return (name: string) => {
            return createTelemetryClient(context.container, name);
        };
    });

    bind<TelemetryClient<U>>(telemetryClientRTTI).to(TelemetryClientImpl);

    bind<TelemetryEventGroupFactory<U>>(telemetryEventGroupFactoryRTTI).toFactory((context: interfaces.Context) => {
        return (telemetryClient: TelemetryClient<object>, name?: string) => {
            return createEventGroup(context.container, telemetryClient, name);
        };
    });

    bind<TelemetryEventGroup<U>>(telemetryEventGroupRTTI).to(TelemetryEventGroupImpl);

    bind<TelemetryProcessor>(telemetryProcessorRTTI).to(TwilioTelemetryProcessor).inSingletonScope();

    bind<TelemetryOptions>(telemetryOptionsRTTI).toConstantValue(getDefaultTelemetryOptions());
});
