















export enum TimeSpan {
    Today = "tasks_today",
    ThirtyMinutes = "tasks_thirty_minutes",
    Yesterday = "tasks_yesterday"
}
