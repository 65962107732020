



export enum ErrorCode {
    


    Forbidden = 45502,
    


    InvalidParams = 45503,
    


    InvalidState = 45505,
    


    NotFound = 45504,
    


    SDK = 45500,
    


    Unknown = 45501,
    


    BadRequest = 70002,
    


    InvalidCertificate = 70251,
    


    InvalidAccessToken = 20101,
    


    AccessTokenExpired = 20104,
    


    ValidationError = 45004,
    


    AuthorizationError = 45003,
    


    PermissionDeniedError = 20003,
    


    TwilsockConnectionError = 45511,
    


    ConnectionError = 45011,
    


    TooManyRequests = 20429
}

export type PublicErrorInfoMap = { [TKey in ErrorCode]: string };

export const publicErrorDetails: PublicErrorInfoMap = {
    [ErrorCode.TwilsockConnectionError]: "Twilsock connection error",
    [ErrorCode.Forbidden]: "User is not authorized to perform the requested operation",
    [ErrorCode.InvalidParams]: "Invalid parameters",
    [ErrorCode.InvalidState]: "Invalid state",
    [ErrorCode.NotFound]: "Resource not found",
    [ErrorCode.SDK]: "Something went wrong, error from inner SDK",
    [ErrorCode.Unknown]: "Unknown error",
    [ErrorCode.ValidationError]: "Validation error: request body validation fails",
    [ErrorCode.AuthorizationError]: "Attempt to access resource where access is not allowed for given credentials",
    [ErrorCode.ConnectionError]: "Connection error",
    [ErrorCode.InvalidAccessToken]: "Invalid Access Token",
    [ErrorCode.AccessTokenExpired]: "Access Token expired or expiration date invalid",
    [ErrorCode.TooManyRequests]: "TooManyRequests: Twilsock rate limit exceeded",
    [ErrorCode.BadRequest]: "BadRequest: The data in the request is unparsable",
    [ErrorCode.InvalidCertificate]: "Validation error: invalid certificate",
    [ErrorCode.PermissionDeniedError]:
        "PermissionDeniedError: You lack permission to the resource and method you requested"
};
