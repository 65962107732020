import { interfaces } from "inversify";
import { ApiServiceBinder as TelemetryBinder } from "./generated/Telemetry/ApiServiceBinder";
import { ApiServiceBinder as RealtimeQueueStatsBinder } from "./generated/RealtimeQueueStats/ApiServiceBinder";
import { ApiServiceBinder as ConfigurationServiceBinder } from "./generated/Configuration/ApiServiceBinder";

import { TwilsockAdapter } from "./TwilsockAdapter/TwilsockAdapter";
import { syncAdapterRTTI, twilsockAdapterRTTI, httpAdapterRTTI } from "./backend.rtti";
import { SyncAdapter } from "./SyncAdapter/SyncAdapter";
import { SyncAdapterImpl } from "./SyncAdapter/SyncAdapterImpl";
import { TwilsockAdapterImpl } from "./TwilsockAdapter/TwilsockAdapterImpl";
import { HttpAdapter } from "./HttpAdapter/HttpAdapter";
import { HttpAdapterImpl } from "./HttpAdapter/HttpAdapterImpl";

export function withContainer(container: interfaces.Container): void {
    container.bind<SyncAdapter>(syncAdapterRTTI).to(SyncAdapterImpl).inSingletonScope();
    container.bind<TwilsockAdapter>(twilsockAdapterRTTI).to(TwilsockAdapterImpl).inSingletonScope();
    container.bind<HttpAdapter>(httpAdapterRTTI).to(HttpAdapterImpl).inSingletonScope();

    TelemetryBinder.with(container);
    RealtimeQueueStatsBinder.with(container);
    ConfigurationServiceBinder.with(container);
}
