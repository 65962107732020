import { interfaces } from "inversify";
import { SyncProductId } from "./SyncProductId";
import { Sync } from "~/modules/sync";
import { syncCacheRTTI, syncRTTI } from "../sync.rtti";
import { Session, sessionRTTI } from "~/modules/session";
import { SyncEvent } from "../Sync/SyncEvent";
import { ErrorSeverity, ThrowErrorFromErrorResponseFunction, throwErrorFromErrorResponseRTTI } from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

export const getSync = async (container: interfaces.Container, productId: SyncProductId): Promise<Sync> => {
    const syncCache = container.get<Map<SyncProductId, Sync>>(syncCacheRTTI);
    let sync = syncCache.get(productId);
    if (!sync) {
        const handleSyncDestroyed = () => {
            syncCache.delete(productId);
        };

        sync = container.get<Sync>(syncRTTI);
        syncCache.set(productId, sync);
        sync.on(SyncEvent.Destroyed, handleSyncDestroyed);

        const session = container.get<Session>(sessionRTTI);
        try {
            await sync.connect(session.token, productId);
        } catch (error) {
            const throwErrorFromErrorResponse = container.get<ThrowErrorFromErrorResponseFunction>(
                throwErrorFromErrorResponseRTTI
            );
            syncCache.delete(productId);
            const metadata = {
                module: extractModuleFromPath(__dirname),
                resourceSid: productId,
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            throwErrorFromErrorResponse(error, metadata);
        }
    }

    return sync;
};
