import { TelemetryEvent } from "~/modules/telemetry/";
import { TelemetryBackendEvent } from "~/backend/generated/Telemetry/model/telemetryBackendEvent";
import { mapAllKeysToSnakeCase } from "~/utils/mapKeys";
import { Payload } from "~/backend/generated/Telemetry/model/payload";
import { ErrorCode, ErrorSeverity, ThrowErrorFunction } from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

function instanceOfPayload(object: object): object is Payload {
    return (
        Object.prototype.hasOwnProperty.call(object, "event_name") &&
        Object.prototype.hasOwnProperty.call(object, "client_timestamp")
    );
}

function toBackendEvent(
    throwError: ThrowErrorFunction,
    payloadType: string,
    event: TelemetryEvent,
    groupName?: string,
    sessionData?: object
): TelemetryBackendEvent {
    const mappedSessionData = sessionData === undefined ? {} : mapAllKeysToSnakeCase(sessionData);
    const mappedEventData = {
        ...mapAllKeysToSnakeCase(event),
        client_timestamp: new Date().toISOString(), 
        event_group: groupName 
    };
    if (instanceOfPayload(mappedEventData)) {
        return {
            payload_type: payloadType, 
            payload: {
                ...mappedEventData,
                ...mappedSessionData
            }
        };
    }
    const metadata = {
        module: extractModuleFromPath(__dirname),
        severity: ErrorSeverity.Error,
        source: extractFileNameFromPath(__filename)
    };
    return throwError(ErrorCode.InvalidParams, metadata, "Telemetry event has missing fields");
}

export function toSdkBackendEvents(
    throwError: ThrowErrorFunction,
    payloadType: string,
    groupName?: string,
    sessionData?: object,
    ...events: TelemetryEvent[]
): TelemetryBackendEvent[] {
    return events.map((event: TelemetryEvent) => {
        return toBackendEvent(throwError, payloadType, event, groupName, sessionData);
    });
}
