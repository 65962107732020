


export enum Severity {
    Trace = "trace",
    Debug = "debug",
    Info = "info",
    Warn = "warn",
    Error = "error"
}

export class LogEntry {
    constructor(loggerName: string, severity: Severity = Severity.Debug) {
        this.loggerName = loggerName;
        this.severity = severity;
        this.timestamp = new Date();
        this.context = {};
    }

    readonly loggerName: string;

    readonly timestamp: Date;

    readonly severity: Severity;

    data: unknown[];

    errorCode?: number;

    readonly context?: {
        sessionId?: string;
        userSid?: string;
        resourceSid?: string;
        stack?: string;
    };
}
