import cloneDeep from "lodash/cloneDeep";
import { LoggerOptions } from "~/modules/logger";

const defaultLoggerOptions: LoggerOptions = {
    level: "ERROR"
};

export function getDefaultLoggerOptions(): LoggerOptions {
    return cloneDeep(defaultLoggerOptions);
}
