


export enum ClientEvent {
    TokenExpired = "tokenExpired",
    TokenAboutToExpire = "tokenAboutToExpire",
    TokenUpdated = "tokenUpdated",
    TokenAutoUpdateFailed = "tokenAutoUpdateFailed",
    TokenMaxLifetimeReached = "tokenMaxLifetimeReached",
    ConnectionLost = "connectionLost",
    ConnectionRestored = "connectionRestored",
    Disconnected = "disconnected"
}
