import { TasksByTime } from "./TasksByTime/TasksByTime";
import { Subscribable } from "~/modules/sync";
import type { WorkerStats } from "~/backend/generated/RealtimeQueueStats/model/workerStats";
import type { ActivityStats } from "~/backend/generated/RealtimeQueueStats/model/activityStats";

export type { WorkerStats, ActivityStats };





export interface QueueStats {
    readonly sid: string;

    readonly friendlyName: string;

    readonly tasks: TasksByTime;

    readonly dateUpdated: Date;

    


    getWorkerStats(): Promise<Subscribable<WorkerStats>>;

    




    getTaskChannelStats(sid: string): Promise<Subscribable<TaskChannelStats>>;

    


    getAllTaskChannelStats(): Promise<Subscribable<TaskChannelStats>[]>;
}





export interface TaskChannelStats {
    sid: string;
    friendlyName: string;

    tasks: TasksByTime;
}





export enum QueueStatsItemKey {
    Metadata = "metadata"
}






export type QueueStatsCallback = (key: QueueStatsItemKey) => void;
