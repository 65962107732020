import { interfaces } from "inversify";
import { SyncClient } from "twilio-sync";
import { Client, ClientOptions, clientRTTI, clientOptionsRTTI } from "~/modules/client";
import { Session, sessionRTTI } from "~/modules/session";
import { mergeUserOptions } from "~/utils/mergeUserOptions";
import { assertNotEmptyString } from "~/utils/assert";
import {
    TelemetrySdkEvent,
    TelemetrySdkEventGroup,
    TelemetrySdkEventName,
    TelemetrySdkEventSource,
    TelemetrySdkClient,
    telemetrySdkClientRTTI,
    SDKDependency
} from "~/modules/telemetrySdkClient";

import { Logger, LoggerFactory, loggerFactoryRTTI, LoggerName } from "~/modules/logger";
import {
    ErrorCode,
    throwErrorFromErrorResponseRTTI,
    throwErrorRTTI,
    ThrowErrorFunction,
    ThrowErrorFromErrorResponseFunction,
    ErrorSeverity
} from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { DeepPartial } from "~/utils/DeepPartial";

const sendClientInitEvent = async (logger: Logger, telemetrySdkClient: TelemetrySdkClient, durationInMs: number) => {
    try {
        const group = telemetrySdkClient.createEventGroup<TelemetrySdkEvent>(TelemetrySdkEventGroup.Default);
        await group.addEvents({
            eventName: TelemetrySdkEventName.ClientInitialized,
            eventSource: TelemetrySdkEventSource.Client,
            durationMs: durationInMs
        });
    } catch (e) {
        logger.error("Failed to send client init event", e);
    }
};

export async function createClient(
    container: interfaces.Container,
    token: string,
    userOptions?: DeepPartial<ClientOptions>
): Promise<Client> {
    const t0Ms = Date.now();

    assertNotEmptyString(token, "token");
    const clientOptions = container.get<ClientOptions>(clientOptionsRTTI);
    const getLogger = container.get<LoggerFactory>(loggerFactoryRTTI);
    const logger = getLogger(LoggerName.Client);
    const throwError = container.get<ThrowErrorFunction>(throwErrorRTTI);
    const throwErrorFromErrorResponse = container.get<ThrowErrorFromErrorResponseFunction>(
        throwErrorFromErrorResponseRTTI
    );

    mergeUserOptions(clientOptions, userOptions);

    const session = container.get<Session>(sessionRTTI);

    try {
        await session.init(token);
    } catch (err) {
        const metadata = {
            module: extractModuleFromPath(__dirname),
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        
        
        if (err.code === 2002 || err.code === 20003) {
            metadata.source = "Twilsock";
            
            throwError(ErrorCode.Forbidden, metadata, "Insufficient permissions", err.source);
        } else {
            throwErrorFromErrorResponse(err, metadata);
        }
    }

    try {
        const telemetrySessionData = {
            dependencies: {
                [SDKDependency.TwilioSync]: SyncClient.version,
                [SDKDependency.Twilsock]: "default"
            }
        };

        const telemetrySdkClient = container.get<TelemetrySdkClient>(telemetrySdkClientRTTI);
        await telemetrySdkClient.setSessionData(telemetrySessionData);

        const durationMs = Date.now() - t0Ms;
        
        await sendClientInitEvent(logger, telemetrySdkClient, durationMs);
    } catch (e) {
        logger.error("Failed to set session data for telemetry", e);
    }

    const client = container.get<Client>(clientRTTI);
    return client;
}
