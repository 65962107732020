import { interfaces } from "inversify";

import twilioChatLoglevel from "loglevels/twilio-chat";
import twilioSyncLoglevel from "loglevels/twilio-sync";
import twilsockLoglevel from "loglevels/twilsock";
import * as defaultLoglevel from "loglevel";

import {
    loglevelFactoryRTTI,
    newableLoggerRTTI,
    defaultPluginsRTTI,
    loggerOptionsRTTI,
    loggerInstancesRTTI
} from "~/modules/logger/logger.rtti";
import { LoglevelFactory, LoggerPlugin, LoggerInstances } from "./LoggerFactory";
import { LoggerOptions, Logger, Loglevel } from "~/modules/logger";
import { assertString } from "~/utils/assert";
import { InternalLoggerName, SdkLoggerName } from "~/modules/logger/Logger/LoggerName";
import { NewableLogger } from "~/modules/logger/Logger/Logger";

const sdkLoggerNameToLoglevel: { [name in SdkLoggerName]: defaultLoglevel.RootLogger } = {
    [SdkLoggerName.TwilioChat]: twilioChatLoglevel,
    [SdkLoggerName.TwilioSync]: twilioSyncLoglevel,
    [SdkLoggerName.Twilsock]: twilsockLoglevel
};

const getSdkLoglevel = (name: SdkLoggerName) => {
    return sdkLoggerNameToLoglevel[name].getLogger(name);
};

export const getLogger = (container: interfaces.Container) => (name: InternalLoggerName): Logger => {
    assertString(name, "name");

    const loggers = container.get<LoggerInstances>(loggerInstancesRTTI);
    let logger = loggers.get(name);
    if (logger) {
        return logger;
    }

    const defaultLevel = container.get<LoggerOptions>(loggerOptionsRTTI).level;
    const loglevelFactory = container.get<LoglevelFactory>(loglevelFactoryRTTI);
    const plugins = container.get<LoggerPlugin[]>(defaultPluginsRTTI);

    let loglevel;
    if (Object.keys(sdkLoggerNameToLoglevel).includes(name)) {
        loglevel = getSdkLoglevel(name as SdkLoggerName);
    } else {
        loglevel = loglevelFactory(name);
    }

    loglevel = plugins.reduce((instance: Loglevel, plugin: LoggerPlugin) => plugin(instance), loglevel);

    const LoggerConstructor = container.get<NewableLogger>(newableLoggerRTTI);
    logger = new LoggerConstructor(loglevel);

    logger.setLevel(defaultLevel);

    loggers.set(name, logger);
    return logger;
};
