import { ContainerModule, interfaces } from "inversify";
import { telemetrySdkClientRTTI, telemetrySdkErrorEventGroupRTTI } from "./telemetrySdkClient.rtti";
import { TelemetrySdkClient } from "./TelemetrySdkClient/TelemetrySdkClient";
import { TelemetrySDKSessionData } from "./TelemetrySdkClient/TelemetrySDKSessionData";
import { createTelemetryClient, TelemetryEventGroup, TelemetryErrorEvent } from "~/modules/telemetry";

export const telemetrySdkClientModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<TelemetrySdkClient>(telemetrySdkClientRTTI)
        .toDynamicValue((context: interfaces.Context) => {
            return createTelemetryClient<TelemetrySDKSessionData>(context.container, "sdk-v1");
        })
        .inSingletonScope();
    bind<TelemetryEventGroup<TelemetryErrorEvent>>(telemetrySdkErrorEventGroupRTTI)
        .toDynamicValue((context: interfaces.Context) => {
            const errorClient = context.container.get<TelemetrySdkClient>(telemetrySdkClientRTTI);
            const group = errorClient.createEventGroup<TelemetryErrorEvent>();
            return group;
        })
        .inSingletonScope();
});
