import { getMessage } from "~/modules/error/ErrorCode/ErrorCodeHelper";
import { ErrorCode } from "~/modules/error/ErrorCode/ErrorCodes";
import { ErrorSeverity } from "~/modules/error/ErrorSeverity/ErrorSeverity";
import { FlexSdkErrorMetadata } from "~/modules/error/FlexSdkErrorMetadata/FlexSdkErrorMetadata";





export class FlexSdkError extends Error {
    readonly #details: string | undefined;

    readonly #errorCode: ErrorCode;

    readonly #wrappedError: Error | undefined;

    readonly #metadata: FlexSdkErrorMetadata;

    constructor(errorCode: ErrorCode, metadata?: FlexSdkErrorMetadata, details?: string, wrappedError?: Error) {
        super(`${getMessage(errorCode) + (details !== undefined ? `: ${details}` : "")} (${errorCode})`);
        this.#errorCode = errorCode;
        this.#details = details;
        this.#metadata = metadata || { severity: ErrorSeverity.Error };
        this.#wrappedError = wrappedError;

        Object.setPrototypeOf(this, FlexSdkError.prototype);
    }

    





    public get code(): ErrorCode {
        return this.#errorCode;
    }

    





    public get details(): string | undefined {
        return this.#details;
    }

    





    public get wrappedError(): Error | undefined {
        return this.#wrappedError;
    }

    





    public get metadata(): FlexSdkErrorMetadata {
        return { ...this.#metadata };
    }
}
