











import { inject, injectable } from "inversify";

import { ErrorCode, ErrorSeverity, ThrowErrorFunction, throwErrorRTTI } from "~/modules/error";
import { SyncAdapter } from "~/backend/SyncAdapter/SyncAdapter";
import { syncAdapterRTTI } from "~/backend/backend.rtti";
import { SyncDocument, SyncList, SyncMap, SyncMapItem } from "~/modules/sync";
import { extractFileNameFromPath } from "~/utils/extractFromPath";

import { CurrentTasksStatsBucket } from "../model/currentTasksStatsBucket";
import { HistoricalTasksStatsBucket } from "../model/historicalTasksStatsBucket";
import { Metadata } from "../model/metadata";
import { TimeSpan } from "../model/timeSpan";
import { WorkerStats } from "../model/workerStats";

import { QueueStatsService } from "./queueStats.serviceInterface";

@injectable()
export class QueueStatsServiceImpl implements QueueStatsService {
    private readonly syncAdapter: SyncAdapter;

    readonly #throwError: ThrowErrorFunction;

    constructor(
        @inject(syncAdapterRTTI) syncAdapter: SyncAdapter,
        @inject(throwErrorRTTI) throwError: ThrowErrorFunction
    ) {
        this.syncAdapter = syncAdapter;
        this.#throwError = throwError;
    }

    




    public async getCurrentTasksStats(queueSid: string): Promise<SyncMapItem<CurrentTasksStatsBucket>> {
        if (queueSid === null || queueSid === undefined) {
            const metadata = {
                module: "backend",
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter queueSid was null or undefined.");
        }

        const syncObject = (await this.syncAdapter.getSyncObjectByPath(
            `/flex_insights/Maps/${encodeURIComponent(String(queueSid))}.realtime_statistics.v1/Items/tasks_now`
        )) as SyncMapItem<CurrentTasksStatsBucket>;
        return syncObject;
    }

    





    public async getHistoricalStats(
        queueSid: string,
        timeSpanBucketKey: TimeSpan
    ): Promise<SyncMapItem<HistoricalTasksStatsBucket>> {
        if (queueSid === null || queueSid === undefined) {
            const metadata = {
                module: "backend",
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter queueSid was null or undefined.");
        }

        if (timeSpanBucketKey === null || timeSpanBucketKey === undefined) {
            const metadata = {
                module: "backend",
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            this.#throwError(
                ErrorCode.InvalidParams,
                metadata,
                "Required parameter timeSpanBucketKey was null or undefined."
            );
        }

        const syncObject = (await this.syncAdapter.getSyncObjectByPath(
            `/flex_insights/Maps/${encodeURIComponent(
                String(queueSid)
            )}.realtime_statistics.v1/Items/${encodeURIComponent(String(timeSpanBucketKey))}`
        )) as SyncMapItem<HistoricalTasksStatsBucket>;
        return syncObject;
    }

    




    public async getMetadata(queueSid: string): Promise<SyncMapItem<Metadata>> {
        if (queueSid === null || queueSid === undefined) {
            const metadata = {
                module: "backend",
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter queueSid was null or undefined.");
        }

        const syncObject = (await this.syncAdapter.getSyncObjectByPath(
            `/flex_insights/Maps/${encodeURIComponent(String(queueSid))}.realtime_statistics.v1/Items/metadata`
        )) as SyncMapItem<Metadata>;
        return syncObject;
    }

    




    public async getWorkerStats(queueSid: string): Promise<SyncMapItem<WorkerStats>> {
        if (queueSid === null || queueSid === undefined) {
            const metadata = {
                module: "backend",
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            this.#throwError(ErrorCode.InvalidParams, metadata, "Required parameter queueSid was null or undefined.");
        }

        const syncObject = (await this.syncAdapter.getSyncObjectByPath(
            `/flex_insights/Maps/${encodeURIComponent(
                String(queueSid)
            )}.realtime_statistics.v1/Items/worker_activities_statistics`
        )) as SyncMapItem<WorkerStats>;
        return syncObject;
    }
}
