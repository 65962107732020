import { SyncMap as SyncSdkSyncMap } from "twilio-sync";

import { SyncMap, SyncMapItem } from "~/modules/sync";
import { SyncMapItemProvider } from "~/modules/sync/SyncMapItemProvider/SyncMapItemProvider";

export class SyncMapImpl implements SyncMap {
    readonly #syncSdkSyncMap: SyncSdkSyncMap;

    readonly #syncMapItemProvider: SyncMapItemProvider;

    constructor(syncSdkSyncMap: SyncSdkSyncMap, syncMapItemProvider: SyncMapItemProvider) {
        this.#syncSdkSyncMap = syncSdkSyncMap;
        this.#syncMapItemProvider = syncMapItemProvider;
    }

    get sid(): string {
        return this.#syncSdkSyncMap.sid;
    }

    get dateUpdated(): Date {
        return this.#syncSdkSyncMap.dateUpdated;
    }

    get uniqueName(): string {
        return this.#syncSdkSyncMap.uniqueName;
    }

    async get(key: string): Promise<SyncMapItem<object>> {
        return this.#syncMapItemProvider(this.#syncSdkSyncMap, key);
    }

    on(event: string, listener: (...args: unknown[]) => void): void {
        this.#syncSdkSyncMap.on(event, listener);
    }

    removeListener(event: string, listener: (...args: unknown[]) => void): void {
        this.#syncSdkSyncMap.removeListener(event, listener);
    }
}
