import { interfaces } from "inversify";
import { NewableTasksByTime } from "~/modules/stats/RealtimeStats/QueueStats/TasksByTime/NewableTasksByTime";
import { TasksByTime } from "~/modules/stats";
import { newableTasksByTimeRTTI } from "~/modules/stats/stats.rtti";
import { queueStatsServiceRTTI } from "~/backend/backend.rtti";
import { QueueStatsService } from "~/backend/generated/RealtimeQueueStats/api/queueStats.serviceInterface";
import {
    ReThrowErrorWithCustomMessageFunction,
    reThrowErrorWithCustomMessageRTTI,
    ThrowErrorFromErrorResponseFunction,
    throwErrorFromErrorResponseRTTI
} from "~/modules/error";

export function getTasksByTime(container: interfaces.Container, queueSid: string): TasksByTime {
    const TaskByTimeConstructor = container.get<NewableTasksByTime>(newableTasksByTimeRTTI);
    const queueStatsService = container.get<QueueStatsService>(queueStatsServiceRTTI);

    const throwErrorFromErrorResponse = container.get<ThrowErrorFromErrorResponseFunction>(
        throwErrorFromErrorResponseRTTI
    );
    const reThrowErrorWithCustomMessage = container.get<ReThrowErrorWithCustomMessageFunction>(
        reThrowErrorWithCustomMessageRTTI
    );

    const instance = new TaskByTimeConstructor(
        queueSid,
        queueStatsService,
        throwErrorFromErrorResponse,
        reThrowErrorWithCustomMessage
    );

    return instance;
}
