import { injectable, inject } from "inversify";
import { IdpConfig, TokenData, Authenticator } from "~/modules/auth";
import {
    ThrowErrorFromResponseFunction,
    InternalError,
    throwErrorFromResponseRTTI,
    ErrorSeverity
} from "~/modules/error";
import { assertNotEmptyString } from "~/utils/assert";
import { Logger, loggerFactoryRTTI, LoggerFactory, LoggerName } from "~/modules/logger";
import { EnvironmentConfig, environmentConfigRTTI } from "~/modules/config";
import { authenticatorDataContainerRTTI } from "~/modules/auth/auth.rtti";
import { AuthenticatorDataContainer } from "~/modules/auth/AuthenticatorDataContainer/AuthenticatorDataContainer";
import * as FederatedAuthHelper from "./FederatedAuthHelper";
import { TokenRefreshResult } from "~/modules/auth/Authenticator/Authenticator";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

@injectable()
export class FederatedAuth implements Authenticator {
    readonly #environmentConfig: EnvironmentConfig;

    readonly #authenticatorDataContainer: AuthenticatorDataContainer;

    readonly #logger: Logger;

    readonly #throwErrorFromResponse: ThrowErrorFromResponseFunction;

    constructor(
        @inject(environmentConfigRTTI) envConfig: EnvironmentConfig,
        @inject(authenticatorDataContainerRTTI) authenticatorDataContainer: AuthenticatorDataContainer,
        @inject(loggerFactoryRTTI) getLogger: LoggerFactory,
        @inject(throwErrorFromResponseRTTI) throwErrorFromResponse: ThrowErrorFromResponseFunction
    ) {
        this.#environmentConfig = envConfig;
        this.#authenticatorDataContainer = authenticatorDataContainer;
        this.#logger = getLogger(LoggerName.Auth);
        this.#throwErrorFromResponse = throwErrorFromResponse;
    }

    public async getIdpUrl(config: IdpConfig): Promise<string> {
        assertNotEmptyString(config.redirectUrl, "redirect url");

        const headers = new Headers({
            "Content-Type": "application/json"
        });

        const payload = FederatedAuthHelper.getSSOLoginRequestBody(config);
        const authServiceUrl = this.#environmentConfig.authServiceUrl;
        const accountSid = this.#authenticatorDataContainer.accountSid;
        const url = `${authServiceUrl}/${accountSid}/authenticate`;

        const response = await fetch(url, {
            headers,
            method: "POST",
            body: payload
        });

        if (!response.ok) {
            const metadata = {
                module: extractModuleFromPath(__dirname),
                resourceSid: accountSid,
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            await this.#throwErrorFromResponse(response, metadata);
        }

        const data = await response.json();
        if (!data.location) {
            this.#logger.error("No redirect location from /authenticate request, data: ", data);
            throw new InternalError("Invalid response from /authenticate endpoint");
        }
        return data.location;
    }

    async validateToken(token: string): Promise<TokenData> {
        assertNotEmptyString(token, "token");

        const headers = new Headers({
            Authorization: `Basic ${btoa(`token:${token}`)})`,
            "Content-Type": "application/json"
        });

        const authServiceUrl = this.#environmentConfig.authServiceUrl;
        const accountSid = this.#authenticatorDataContainer.accountSid;
        const url = `${authServiceUrl}/${accountSid}/Tokens/validate`;
        const response = await fetch(url, {
            method: "POST",
            headers,
            body: JSON.stringify({ token })
        });

        if (!response.ok) {
            const metadata = {
                module: extractModuleFromPath(__dirname),
                resourceSid: accountSid,
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            await this.#throwErrorFromResponse(response, metadata);
        }

        const { roles, valid, expiration } = await response.json();
        const dateExpired = new Date(expiration);
        return { roles, valid, dateExpired };
    }

    async refreshToken(token: string): Promise<TokenRefreshResult> {
        assertNotEmptyString(token, "token");

        const authServiceUrl = this.#environmentConfig.authServiceUrl;
        const accountSid = this.#authenticatorDataContainer.accountSid;
        const url = `${authServiceUrl}/${accountSid}/Tokens/refresh`;

        const headers = new Headers({
            "Content-Type": "application/json"
        });

        const response = await fetch(url, {
            headers,
            method: "POST",
            body: JSON.stringify({ token })
        });

        if (!response.ok) {
            const metadata = {
                module: extractModuleFromPath(__dirname),
                resourceSid: accountSid,
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            await this.#throwErrorFromResponse(response, metadata, "Could not refresh token");
        }

        const { token: newToken, expiration } = await response.json();
        const dateExpired = new Date(expiration);
        return { token: newToken, dateExpired };
    }
}
