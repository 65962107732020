import mergeWith from "lodash/mergeWith";
import { ErrorCode, ErrorSeverity, FlexSdkError } from "~/modules/error";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";
import { assertObject } from "./assert";
import { DeepPartial } from "./DeepPartial";

export function mergeUserOptions<T>(options: T, userOptions?: DeepPartial<T>): void {
    if (typeof userOptions !== "undefined") {
        assertObject(userOptions, "userOptions");
    }

    
    const customMerger = (
        value: undefined | Object,
        _srcValue: never,
        key: string,
        
        _object: object,
        
        _source: never
    ) => {
        const objectHasKey = Object.prototype.hasOwnProperty.call(_object, key);
        
        const objectHasGetterForKey = Object.prototype.hasOwnProperty.call(Object.getPrototypeOf(_object), key);
        if (!objectHasKey && !objectHasGetterForKey) {
            const metadata = {
                module: extractModuleFromPath(__dirname),
                severity: ErrorSeverity.Error,
                source: extractFileNameFromPath(__filename)
            };
            
            throw new FlexSdkError(ErrorCode.InvalidParams, metadata, `${key} is not a valid property`);
        }

        return undefined; 
    };

    mergeWith(options, userOptions, customMerger);
}
