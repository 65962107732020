import { TwilsockClient } from "twilsock";

export { TwilsockClient };

export type TwilsockOptions = {
    region?: string;
    clientMetadata: {
        type: string;
        sdk: string;
        sdkv: string;
        app?: string;
        appv?: string;
    };
};

export enum TwilsockClientEvent {
    TokenExpired = "tokenExpired",
    TokenAboutToExpire = "tokenAboutToExpire",
    StateChanged = "stateChanged",
    ConnectionError = "connectionError",
    Connected = "connected",
    Disconnected = "disconnected"
}

export interface NewableTwilsockClient {
    new (token: string, productId: string, options?: TwilsockOptions): TwilsockClient;
}
