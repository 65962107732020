import { ContainerModule, interfaces } from "inversify";
import { requiredRole } from "./RequiredRole/requiredRole";
import { rbacRTTI } from "./rbac.rtti";
import { Rbac } from "./Rbac";
import { hasRole } from "./HasRole/hasRole";

export const rbacModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<Rbac>(rbacRTTI).toDynamicValue((context: interfaces.Context) => {
        return {
            requiredRole: requiredRole(context.container),
            hasRole: hasRole(context.container)
        };
    });
});
