import { ContainerModule, interfaces } from "inversify";
import {
    realtimeStatsRTTI,
    newableTasksByTimeRTTI,
    newableQueueStatsRTTI,
    tasksByTimeFactoryRTTI,
    queueStatsProviderRTTI
} from "./stats.rtti";

import { NewableQueueStats } from "./RealtimeStats/QueueStats/NewableQueueStats";
import { QueueStatsProvider } from "./RealtimeStats/QueueStatsProvider/QueueStatsProvider";
import { getQueueStats } from "./RealtimeStats/QueueStatsProvider/getQueueStats";
import { QueueStatsImpl } from "./RealtimeStats/QueueStats/QueueStatsImpl/QueueStatsImpl";

import { TasksByTimeImpl } from "./RealtimeStats/QueueStats/TasksByTime/TasksByTimeImpl";
import { NewableTasksByTime } from "./RealtimeStats/QueueStats/TasksByTime/NewableTasksByTime";
import { getTasksByTime } from "./RealtimeStats/QueueStats/TasksByTimeFactory/getTasksByTime";
import { TasksByTimeFactory } from "./RealtimeStats/QueueStats/TasksByTimeFactory/TasksByTimeFactory";

import { RealtimeStats } from "./RealtimeStats/RealtimeStats";
import { RealtimeStatsImpl } from "./RealtimeStats/RealtimeStatsImpl";

export const statsModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<RealtimeStats>(realtimeStatsRTTI).to(RealtimeStatsImpl).inSingletonScope();

    bind<NewableQueueStats>(newableQueueStatsRTTI).toConstructor(QueueStatsImpl);
    bind<QueueStatsProvider>(queueStatsProviderRTTI).toProvider((context: interfaces.Context) => {
        return (queueSid: string) => {
            return getQueueStats(context.container, queueSid);
        };
    });

    bind<NewableTasksByTime>(newableTasksByTimeRTTI).toConstructor(TasksByTimeImpl);
    bind<TasksByTimeFactory>(tasksByTimeFactoryRTTI).toFactory((context: interfaces.Context) => {
        return (queueSid: string) => {
            return getTasksByTime(context.container, queueSid);
        };
    });
});
