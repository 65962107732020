import { interfaces } from "inversify";
import { QueueStats } from "~/modules/stats";
import { NewableQueueStats } from "~/modules/stats/RealtimeStats/QueueStats/NewableQueueStats";
import { TasksByTimeFactory } from "~/modules/stats/RealtimeStats/QueueStats/TasksByTimeFactory/TasksByTimeFactory";
import { tasksByTimeFactoryRTTI, newableQueueStatsRTTI } from "~/modules/stats/stats.rtti";
import { queueStatsServiceRTTI } from "~/backend/backend.rtti";
import { QueueStatsService } from "~/backend/generated/RealtimeQueueStats/api/queueStats.serviceInterface";
import {
    InternalErrorCode,
    ThrowErrorFromErrorResponseFunction,
    throwErrorFromErrorResponseRTTI,
    ReThrowErrorWithCustomMessageFunction,
    reThrowErrorWithCustomMessageRTTI,
    ErrorSeverity
} from "~/modules/error";
import { Subscribable } from "~/modules/sync";
import { QueueStatsCallback } from "~/modules/stats/RealtimeStats/QueueStats/QueueStats";
import { extractFileNameFromPath, extractModuleFromPath } from "~/utils/extractFromPath";

export async function getQueueStats(
    container: interfaces.Container,
    queueSid: string
): Promise<Subscribable<QueueStats, QueueStatsCallback>> {
    const getTasksByTime = container.get<TasksByTimeFactory>(tasksByTimeFactoryRTTI);
    const tasksByTime = getTasksByTime(queueSid);

    const QueueStatsConstructor = container.get<NewableQueueStats>(newableQueueStatsRTTI);
    const queueStatsService = container.get<QueueStatsService>(queueStatsServiceRTTI);

    const throwErrorFromErrorResponse = container.get<ThrowErrorFromErrorResponseFunction>(
        throwErrorFromErrorResponseRTTI
    );
    const reThrowErrorWithCustomMessage = container.get<ReThrowErrorWithCustomMessageFunction>(
        reThrowErrorWithCustomMessageRTTI
    );

    try {
        const metadata = await queueStatsService.getMetadata(queueSid);
        const instance = new QueueStatsConstructor(
            queueSid,
            queueStatsService,
            tasksByTime,
            metadata,
            throwErrorFromErrorResponse,
            reThrowErrorWithCustomMessage
        );
        return instance;
    } catch (error) {
        if (error.wrappedError?.code === InternalErrorCode.SyncNameNotFoundError) {
            reThrowErrorWithCustomMessage(error, `Metadata statistics of the queue: ${queueSid}`);
        }
        const metadata = {
            module: extractModuleFromPath(__dirname),
            resourceSid: queueSid,
            severity: ErrorSeverity.Error,
            source: extractFileNameFromPath(__filename)
        };
        return throwErrorFromErrorResponse(error, metadata);
    }
}
