import { injectable } from "inversify";
import { Logger, LoggerLevel, LoggerLevelNumbers, Loglevel, LogEntry } from "~/modules/logger";

@injectable()
export class LoggerImpl implements Logger {
    readonly #loglevel: Loglevel;

    constructor(loglevel: Loglevel) {
        this.#loglevel = loglevel;
    }

    trace = (...args: unknown[] | LogEntry[]): void => {
        this.#loglevel.trace(...args);
    };

    debug = (...args: unknown[] | LogEntry[]): void => {
        this.#loglevel.debug(...args);
    };

    


    log = (...args: unknown[] | LogEntry[]): void => {
        this.#loglevel.debug(...args);
    };

    info = (...args: unknown[] | LogEntry[]): void => {
        this.#loglevel.info(...args);
    };

    warn = (...args: unknown[] | LogEntry[]): void => {
        this.#loglevel.warn(...args);
    };

    error = (...args: unknown[] | LogEntry[]): void => {
        this.#loglevel.error(...args);
    };

    setLevel = (level: LoggerLevel): void => {
        this.#loglevel.setLevel(level);
    };

    getLevel = (): LoggerLevelNumbers => {
        return this.#loglevel.getLevel();
    };
}
