import { Container, interfaces } from "inversify";

export function createPureFactory<T extends (...args: unknown[]) => unknown>(containerCreator: () => Container) {
    return (serviceIdentifier: interfaces.ServiceIdentifier<T>): T => {
        const factory = (...args: unknown[]): unknown => {
            const container = containerCreator();
            const provider = container.get<T>(serviceIdentifier);
            return provider(...args);
        };
        return factory as T;
    };
}
