import { LogLevelNumbers, MethodFactory } from "loglevel";
import { LogEntry } from "~/modules/logger";

export const setData = (originalFactory: MethodFactory) => (
    methodName: string,
    logLevel: LogLevelNumbers,
    loggerName: string
) => {
    return (logEntry: LogEntry, ...messages: unknown[]): void => {
        if (!logEntry.data) {
            
            logEntry.data = messages;
        }

        const method = originalFactory(methodName, logLevel, loggerName);
        return method(logEntry, ...messages);
    };
};
