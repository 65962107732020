import { interfaces } from "inversify";
import {
    NewableTwilsockClient,
    TwilsockOptions,
    TwilsockClient
} from "~/modules/websocket/TwilsockClient/TwilsockClient";
import { newableTwilsockClientRTTI } from "~/modules/websocket/websocket.rtti";

export function getTwilsockClient(
    container: interfaces.Container,
    token: string,
    productId: string,
    options?: TwilsockOptions
): TwilsockClient {
    const TwilsockClientConstructor = container.get<NewableTwilsockClient>(newableTwilsockClientRTTI);
    return new TwilsockClientConstructor(token, productId, options);
}
