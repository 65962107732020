import camelcase from "lodash/camelCase";
import snakecase from "lodash/snakeCase";
import mapkeys from "lodash/mapKeys";
import transform from "lodash/transform";
import { assertNotCircularObject } from "~/utils/assert";

interface LooseObject {
    [key: string]: unknown;
}

const mapArrayKeys = <T extends object>(object: T[], cb: (key: string) => string) => {
    const newArray = [] as object[];
    object.forEach((item: T) => {
        let newValue: T;
        if (Array.isArray(item)) {
            newValue = mapArrayKeys(item, cb) as T;
        } else if (typeof item === "object") {
            
            newValue = mapAllKeys(item, cb) as T;
        } else {
            newValue = item;
        }
        newArray.push(newValue);
    });
    return newArray;
};

const mapAllKeys = <T extends object>(object: T, cb: (key: string) => string) => {
    
    return transform<any, LooseObject>(
        object,
        (result: LooseObject, value: Object, key: string) => {
            if (Array.isArray(value)) {
                
                result[`${cb(key)}`] = mapArrayKeys(value, cb);
            } else if (value instanceof Object && !(value instanceof Date)) {
                
                result[`${cb(key)}`] = mapAllKeys(value, cb);
            } else {
                
                result[`${cb(key)}`] = value;
            }
        },
        {}
    );
};

export const mapKeysToCamelCase = <T extends object>(object: T): LooseObject =>
    mapAllKeys(object, (key: string) => camelcase(key));

const mapRootKeysTo = <T extends object>(object: T, cb: (key: string) => string) =>
    
    mapkeys<any>(object, (_, key: string) => cb(key));

export const mapRootKeysToSnakeCase = <T extends object>(object: T): LooseObject =>
    mapRootKeysTo(object, (key: string) => snakecase(key));

export const mapRootKeysToCamelCase = <T extends object>(object: T): object =>
    mapRootKeysTo(object, (key: string) => camelcase(key));

export const mapAllKeysToSnakeCase = <T extends object>(object: T): LooseObject => {
    assertNotCircularObject(object, "key-value dictionary");
    return mapAllKeys(object, (key: string) => snakecase(key));
};
