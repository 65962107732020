



export enum ErrorSeverity {
    Fatal = "fatal",
    Error = "error",
    Warning = "warning",
    Info = "info"
}
