export function extractModuleFromPath(path: string): string {
    const regex = "/modules/[A-z]+";
    const match = path.match(regex);

    const module = match ? match[0].replace("/modules/", "") : "";
    return module;
}

export function extractFileNameFromPath(path: string): string {
    const regex = "([A-z])+([.]*[A-z]*)[.][jt]s";
    const match = path.match(regex);

    const fileName = match ? match[0] : "";
    return fileName;
}
