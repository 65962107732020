import { interfaces } from "inversify";
import { telemetryEventGroupRTTI } from "~/modules/telemetry/telemetry.rtti";
import { TelemetryClient, TelemetryEventGroup } from "~/modules/telemetry";

export function createEventGroup<T>(
    container: interfaces.Container,
    telemetryClient: TelemetryClient<object>,
    name?: string
): TelemetryEventGroup<T> {
    const eventGroup = container.get<TelemetryEventGroup<T>>(telemetryEventGroupRTTI);
    eventGroup.name = name;
    eventGroup.telemetryClient = telemetryClient;
    return eventGroup;
}
