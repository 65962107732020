import { IdpConfig } from "~/modules/auth";

interface SSOLoginRequestBody {
    products: Array<string>;
    resource: string;
}






function sanitizeSSOParams(s: string): string {
    return s.replace(/((Token|Expiration|Identity|Roles)=[^&]+[&]?)/g, "").replace(/[&?]$/, "");
}

export function getSSOLoginRequestBody(config: IdpConfig): string {
    const payload: SSOLoginRequestBody = {
        products: ["flex"],
        resource: sanitizeSSOParams(config.redirectUrl)
    };

    return JSON.stringify(payload);
}
