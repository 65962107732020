



export enum InternalErrorCode {
    


    SyncConnectionError = 45510,
    


    SyncAccessForbiddenError = 54007,
    


    SyncMapNotFoundError = 54200,
    


    SyncMapItemNotFoundError = 54201,
    


    SyncInvalidMapItemDataError = 54206,
    


    SyncInvalidMapItemKeyError = 54209,
    


    SyncNameNotFoundError = 54300,
    


    SyncNameAlreadyExistsError = 54301,
    


    SyncInvalidNameError = 54302
}

export type InternalErrorInfoMap = { [TKey in InternalErrorCode]: string };

export const internalErrorDetails: InternalErrorInfoMap = {
    [InternalErrorCode.SyncConnectionError]: "Sync connection error",
    [InternalErrorCode.SyncAccessForbiddenError]: "Sync access forbidden for identity",
    [InternalErrorCode.SyncMapNotFoundError]: "Sync map not found",
    [InternalErrorCode.SyncInvalidMapItemDataError]: "Invalid sync map item data",
    [InternalErrorCode.SyncInvalidMapItemKeyError]: "Invalid sync map item key",
    [InternalErrorCode.SyncMapItemNotFoundError]: "Sync map item not found",
    [InternalErrorCode.SyncNameNotFoundError]: "Sync unique name not found",
    [InternalErrorCode.SyncNameAlreadyExistsError]: "Sync unique name already exists",
    [InternalErrorCode.SyncInvalidNameError]: "Sync invalid unique name"
};
