import { Logger } from "~/modules/logger";

const HTTP_STATUS_CODE_TOO_MANY_REQUESTS = 429;

function calculateNextDelay(retryCount: number, initialDelay: number, backoff: "exponential" | "linear") {
    if (backoff === "exponential") {
        return 1.5 ** retryCount * initialDelay;
    }
    return (retryCount + 1) * initialDelay;
}

export async function retry<T>(
    functionToRetry: Function,
    logger?: Logger,
    initialDelay = 100,
    maxAttempts = 3,
    retryOnlyOnTooManyRequestsError = true,
    backoff: "exponential" | "linear" = "exponential"
): Promise<T> {
    let retryCount = 0;
    const maxDelay = 15 * 60 * 1000; 
    let errorToReturn;

    do {
        try {
            if (logger && retryCount > 0) {
                logger.debug("Retry attempt: ", retryCount);
            }

            
            return await functionToRetry();
        } catch (error) {
            
            
            const httpErrorCode = error.reply?.status?.code;
            if (retryOnlyOnTooManyRequestsError && httpErrorCode !== HTTP_STATUS_CODE_TOO_MANY_REQUESTS) {
                return Promise.reject(error);
            }
            if (retryCount >= maxAttempts - 1) {
                errorToReturn = error;
                break;
            }
        }
        let delay = calculateNextDelay(retryCount, initialDelay, backoff);

        
        if (delay > maxDelay) {
            delay = maxDelay;
        }

        
        await new Promise((resolve) => setTimeout(resolve, delay));
    } while (retryCount++ < maxAttempts - 1);

    return Promise.reject(errorToReturn);
}
