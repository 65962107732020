import { injectable, inject } from "inversify";
import { Twilsock, twilsockRTTI, TwilsockResult } from "~/modules/websocket";
import { TwilsockAdapter } from "./TwilsockAdapter";

@injectable()
export class TwilsockAdapterImpl implements TwilsockAdapter {
    readonly #twilsock: Twilsock;

    constructor(@inject(twilsockRTTI) twilsockInjected: Twilsock) {
        this.#twilsock = twilsockInjected;
    }

    public async post<U>(url: string, body: object): Promise<TwilsockResult<U>> {
        const result = await this.#twilsock.post<U>(url, {}, body);
        return result;
    }
}
