import { injectable } from "inversify";
import { AccountConfigData } from "~/backend/generated/Configuration/model/accountConfigData";
import { ConfigDataContainer } from "~/modules/config/AccountConfig/ConfigDataContainer";
import { InternalError } from "~/modules/error";

@injectable()
export class AccountConfigDataContainer implements ConfigDataContainer<AccountConfigData> {
    #data?: AccountConfigData;

    get(): AccountConfigData {
        if (!this.#data) {
            throw new InternalError("Config data hasn't been fetched");
        }
        return this.#data;
    }

    set(data: AccountConfigData): void {
        this.#data = data;
    }
}
