import { ContainerModule, interfaces } from "inversify";
import { getLogger as getLoglevel } from "loglevel";
import { Logger, NewableLogger, LoggerOptions } from "./Logger/Logger";
import {
    loggerFactoryRTTI,
    newableLoggerRTTI,
    loglevelFactoryRTTI,
    defaultPluginsRTTI,
    loggerOptionsRTTI,
    loggerInstancesRTTI
} from "./logger.rtti";
import { LoglevelFactory, LoggerPlugin, LoggerInstances } from "./LoggerFactory/LoggerFactory";
import { getLogger } from "./LoggerFactory/getLogger";
import { getDefaultLoggerOptions } from "./DefaultLoggerLevel/defaultLoggerLevel";
import { LoggerImpl } from "./Logger/LoggerImpl/LoggerImpl";
import { logEntryPlugin } from "./plugins/logEntryPlugin";

export const loggerModuleContainer = new ContainerModule((bind: interfaces.Bind) => {
    bind<LoglevelFactory>(loglevelFactoryRTTI).toConstantValue(getLoglevel);
    bind<interfaces.Factory<Logger>>(loggerFactoryRTTI).toFactory<Logger>((context: interfaces.Context) =>
        getLogger(context.container)
    );
    bind<NewableLogger>(newableLoggerRTTI).toConstructor(LoggerImpl);
    bind<LoggerPlugin[]>(defaultPluginsRTTI).toConstantValue([logEntryPlugin]);
    bind<LoggerOptions>(loggerOptionsRTTI).toConstantValue(getDefaultLoggerOptions());
    bind<LoggerInstances>(loggerInstancesRTTI).toConstantValue(new Map());
});
