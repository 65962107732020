import { interfaces } from "inversify";
import { telemetryClientRTTI } from "~/modules/telemetry/telemetry.rtti";
import { TelemetryClient } from "~/modules/telemetry/TelemetryClient/TelemetryClient";

export function createTelemetryClient<U extends object>(
    container: interfaces.Container,
    name: string
): TelemetryClient<U> {
    const telemetryClient = container.get<TelemetryClient<U>>(telemetryClientRTTI);
    telemetryClient.name = name;
    return telemetryClient;
}
