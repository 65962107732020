import { version as packageVersion } from "package.json";
import { createContainer, getSingletonContainer } from "~/ioc.config";
import { publicConfigProviderRTTI, PublicConfigProvider } from "~/modules/config";
import { clientProviderRTTI, ClientProvider } from "~/modules/client";
import { authenticatorFactoryRTTI, twilioIdpUrlRTTI, AuthenticatorFactory, TwilioIdpUrlFunction } from "~/modules/auth";
import { LoggerFactory, loggerFactoryRTTI } from "~/modules/logger";
import { createPureFactory } from "~/ioc.helper";

export type { SessionOptions } from "./modules/session";
export type { DeepPartial } from "./utils/DeepPartial";
export type { Without, XOR } from "./utils/utilityTypes";
export type {
    AccountConfig,
    AccountConfigKeys,
    AccountConfigData,
    IntegrationsConfig,
    OutboundFlows,
    SkillDefinition,
    QueueStatsConfiguration,
    PublicConfig,
    PublicConfigAttributes,
    PublicConfigProvider,
    AccountSidOption,
    RuntimeDomainOption,
    TaskQueue,
    WorkerChannel,
    DefaultQueueStatsConfiguration,
    QueueConfiguration,
    QueueChannelConfiguration
} from "./modules/config";
export type { Logger, LoggerFactory, LoggerOptions, LoggerLevel, LoggerLevelNumbers } from "./modules/logger";
export { LoggerName } from "./modules/logger";
export type { Client, ClientProvider, ClientOptions, ClientConfigType } from "./modules/client";
export { ClientEvent } from "./modules/client";
export type {
    Authenticator,
    AuthenticatorFactory,
    TwilioIdpUrlFunction,
    IdpConfig,
    Role,
    TokenData,
    TokenRefreshResult
} from "./modules/auth";
export type { EventPublisher } from "./modules/events";
export type { Subscribable } from "./modules/sync";
export type {
    TelemetryEvent,
    TelemetryErrorEvent,
    TelemetryEventGroup,
    TelemetryClient,
    TelemetryProcessingResult,
    TelemetryOptions
} from "./modules/telemetry";

export type {
    RealtimeStats,
    QueueStats,
    TaskChannelStats,
    CurrentTasksStats,
    HistoricalTasksStats,
    TasksByTime,
    WorkerStats,
    ActivityStats,
    WorkspaceStats,
    TasksByStatus,
    QueueStatsCallback
} from "./modules/stats";
export { TimeSpan, QueueStatsItemKey } from "./modules/stats";







export const auth = createPureFactory<AuthenticatorFactory>(createContainer)(authenticatorFactoryRTTI);




export const getLogger = createPureFactory<LoggerFactory<string>>(getSingletonContainer)(loggerFactoryRTTI);




export const getTwilioIdpUrl = createPureFactory<TwilioIdpUrlFunction>(createContainer)(twilioIdpUrlRTTI);




export const getPublicConfig = createPureFactory<PublicConfigProvider>(createContainer)(publicConfigProviderRTTI);




export const createClient = createPureFactory<ClientProvider>(createContainer)(clientProviderRTTI);




export const version: string = packageVersion;

export type { FlexSdkErrorMetadata } from "./modules/error";
export { FlexSdkError, ErrorCode, ErrorSeverity } from "./modules/error";
