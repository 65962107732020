


export enum SDKDependency {
    TwilioChat = "twilio_chat",
    TwilioSync = "twilio_sync",
    TwilioClient = "twilio_client",
    Twilsock = "twilsock"
}




export interface TelemetrySDKSessionData {
    


    dependencies?: { [key in SDKDependency]?: string };
}
